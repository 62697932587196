export enum WeightInAppOnboardingTaskStatus {
  NOT_ELIGIBLE = 'Not Eligible',
  PENDING = 'Pending',
  FINISHED = 'Finished',
}

export interface WeightInAppOnboardingTaskVisibility {
  showChecklist: boolean;
  facebookCommunityStatus: WeightInAppOnboardingTaskStatus;
}
