import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { useRef } from 'react';
import { Typography } from '@eucalyptusvc/design-system';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-neutral.svg';
import { ReactComponent as DownloadAppFigure } from 'assets/icons/figure-download-app.svg';
import { neutral900 } from '@eucalyptusvc/design-system/src/theme/shared';
import { useEnvironment } from '@customer-frontend/environment';
import { useIsMobileAppInstalled } from 'pages/consultation/utils';
import { useHistory } from 'react-router-dom';

const mobileBgColors = [
  'bg-secondary-purple-200',
  'bg-tertiary-peach',
  'bg-secondary-lime-200',
];

export const MobileAppBanner = (): React.ReactElement => {
  const bgClassNameRef = useRef(
    mobileBgColors[Math.floor(Math.random() * mobileBgColors.length)],
  );
  const environment = useEnvironment();
  const history = useHistory();

  const isMobileAppInstalled = useIsMobileAppInstalled();

  return (
    <div className="fixed w-full z-10">
      <button
        className="w-full"
        onClick={() => {
          if (isMobileAppInstalled) {
            history.push('mobile-link/tabs/home');
          } else {
            window.open(
              `${environment.landingPageUrl}/mobile-download`,
              '__blank',
            );
          }
        }}
      >
        <div
          className={clsx(
            bgClassNameRef.current,
            'select-none flex items-center justify-between h-12 px-4',
          )}
        >
          <div className="flex items-center">
            <DownloadAppFigure />
            <div className="ml-2 flex items-center">
              <Typography color={neutral900} isBold size={'small-text'}>
                {isMobileAppInstalled ? (
                  <FormattedMessage
                    defaultMessage="Open the Juniper app"
                    description="Prompt for user to open mobile app"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Download the Juniper mobile app"
                    description="Prompt for user to download mobile app"
                  />
                )}
              </Typography>
            </div>
          </div>
          <div>
            <ArrowRight style={{ fill: neutral900, stroke: neutral900 }} />
          </div>
        </div>
      </button>
    </div>
  );
};
