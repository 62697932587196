import React, { useState } from 'react';
import { Token } from '@stripe/stripe-js';
import { ApolloError } from '@apollo/client';

import {
  PaymentCardDisplay,
  PaymentCardForm,
} from './stripe-payment-form/payment-card';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { Button, useNotification } from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';
import { useUpdatePayment } from '@customer-frontend/services';

import { Maybe, PaymentMethod } from '@customer-frontend/graphql-types';
import { useStripeTokenHandler } from '../logic';
import { FormattedMessage, useIntl } from 'react-intl';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';

export const StripeDetailsForm = ({
  stripePaymentMethod,
  onComplete,
}: {
  stripePaymentMethod: Maybe<PaymentMethod>;
  onComplete: () => Promise<void>;
}): React.ReactElement => {
  const [isEditing, setIsEditing] = useState(!stripePaymentMethod);
  const [loading, setLoading] = useState(false);
  const notify = useNotification();
  const config = getConfig();
  const [updatePayment] = useUpdatePayment();
  const { formatMessage } = useIntl();

  const onTokenReceived = async (token?: Token): Promise<void> => {
    if (token?.card) {
      await updatePayment({
        variables: {
          stripeTokenId: token.id,
        },
      });
    }
  };
  const { handleTokenReceived } = useStripeTokenHandler({
    onTokenReceived,
    detailsChanged: isEditing,
  });
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    e,
  ): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    try {
      await handleTokenReceived();
      await onComplete();
    } catch (err) {
      if (err instanceof ApolloError) {
        const descriptions = getErrorMessageDescriptorsFromError(err);
        descriptions.forEach((descriptor) =>
          notify.error({ message: formatMessage(descriptor) }),
        );
      } else {
        notify.error({ message: err.message });
      }
    } finally {
      setLoading(false);
    }
  };
  const { brand, mask, expiry } = stripePaymentMethod?.cardMeta ?? {};

  return (
    <form onSubmit={handleSubmit}>
      {isEditing ? (
        <div>
          <PaymentCardForm />
        </div>
      ) : (
        <PaymentCardDisplay
          brand={brand ?? ''}
          mask={mask ?? ''}
          expiry={expiry ?? ''}
          onClickEdit={() => setIsEditing(true)}
        />
      )}
      <div className="flex flex-col md:flex-row justify-between pt-4 space-x-0 md:space-x-4 space-y-4 md:space-y-0">
        <Button
          isSubmit
          isFullWidth
          palette={getPrimaryButtonPalette(config.brand)}
          isLoading={loading}
        >
          <FormattedMessage defaultMessage="Confirm payment details" />
        </Button>
        {isEditing && stripePaymentMethod && (
          <Button
            isFullWidth
            level={'secondary'}
            onClick={() => setIsEditing(false)}
          >
            <FormattedMessage
              defaultMessage="Cancel"
              description="Button to cancel the editing of the user's payment details"
            />
          </Button>
        )}
      </div>
    </form>
  );
};
