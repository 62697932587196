import { VFC } from 'react';
import clsx from 'clsx';
import { ContentfulContentType } from '@customer-frontend/contentful';
import { EducationReading } from '@customer-frontend/graphql-types';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { white } from '@eucalyptusvc/design-system/src/theme/shared';
import { useEducationTheme } from '../provider';
import { convertBodyRichTextToString, convertMsToMinutes } from './utils';
import { TimeToRead } from './time-to-read';
import { FormattedMessage, useIntl } from 'react-intl';

type NextReadingHeroCardProps = {
  onReadingClick: (readingId: string) => void;
  reading: EducationReading;
};

export const NextReadingHeroCard: VFC<NextReadingHeroCardProps> = ({
  onReadingClick,
  reading,
}) => {
  const theme = useEducationTheme();
  const moduleTheme = theme.brand.nextReadingHeroCard;
  const readingContent = reading.content as ContentfulContentType;
  const readingTitle = readingContent.fields?.title;
  const richText = readingContent.fields?.bodyRichText;
  const richTextAsString = convertBodyRichTextToString(richText);
  const timeToReadMinutes = reading.timeToReadMs
    ? convertMsToMinutes(reading.timeToReadMs)
    : 1;
  const { formatMessage } = useIntl();

  const readingAlreadyStarted = reading.startedAt;

  const beginButtonText = readingAlreadyStarted
    ? formatMessage({
        defaultMessage: 'Continue reading',
      })
    : formatMessage({ defaultMessage: 'Begin reading' });

  const containerClassName = clsx(
    'border',
    'border-primary-300',
    'w-full',
    'overflow-hidden',
    moduleTheme?.containerClassName,
  );

  return (
    <div className={containerClassName}>
      <div className="flex items-center space-x-2 px-4 py-2 bg-primary-500">
        <Typography size="xs" isBold color={white}>
          <FormattedMessage description="Arrow ASCII" defaultMessage="→" />
        </Typography>
        <Typography size="paragraph" color={white} isBold>
          <FormattedMessage
            description="Text signifying next reading"
            defaultMessage="Next Up"
          />
        </Typography>
      </div>
      <div className="p-4">
        <div className="flex space-y-4 flex-col flex-1">
          <div className="space-y-2">
            <Typography
              size="large-paragraph"
              isBold
              color={moduleTheme?.color}
            >
              {readingTitle}
            </Typography>
            <TimeToRead
              icon={moduleTheme?.timeRemainingIcon}
              isBold={moduleTheme?.timeRemainingTextBold}
              colorClassName={moduleTheme?.color}
              minutes={timeToReadMinutes}
            />
            <Typography isItalic size="paragraph" color={moduleTheme?.color}>
              <span className="line-clamp-2">{richTextAsString}</span>
            </Typography>
          </div>
          <Button
            onClick={() => onReadingClick(reading.id)}
            palette={moduleTheme?.buttonPalette}
          >
            {beginButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
