import { useParams } from 'react-router-dom';
import { PurchaseActivationFlow } from '@customer-frontend/page-templates';
import { logger } from 'utils/logging';
import { routes } from 'utils/routes';
import { useTheme } from '@chakra-ui/react';
import { BrandTheme } from '@eucalyptusvc/design-system';
import { MobileAppPrompt } from '../../../components/presentational/mobile-app-prompt';
import { ReactComponent as CircleTick } from 'assets/icons/circle-tick.svg';

const activationRoutes = {
  ...routes.consultation.activatePurchaseFlow,
  pharmacyInformation: routes.consultation.safetyInformation,
  practitionerLetter: routes.consultation.doctorsNote,
  profile: routes.profile,
  handlePurchaseOfferingsIntent: routes.handlePurchaseOfferingsIntent,
};

export default function ActivatePurchaseFlow() {
  const { consultationId } = useParams<{ consultationId: string }>();
  const theme = useTheme<BrandTheme>();

  const orderSummaryCardStyles = {
    headerSize: 'medium-paragraph',
    headerColor: 'text-primary-500',
    headerPadding: 'px-4 md:px-3 py-2',
    headerBgColor: 'bg-primary-100',
    dueTodaySize: 'medium-paragraph',
    dueTodayColor: 'text-primary-500',
    dueTodayBgColor: 'bg-status-info-100',
    dueTodayBorderTColor: 'border-white',
    borderColor: 'border-primary-300',
    dividerBorderColor: 'border-primary-300',
    highlightsTextColor: 'text-primary-500',
  } as const;

  return (
    <PurchaseActivationFlow
      consultationId={consultationId}
      logger={logger}
      routes={activationRoutes}
      styles={{
        layout: {
          mainBgColorClassName: 'bg-neutral-white',
          sideBgColorClassName: 'bg-primary-500 bg-opacity-10',
          headerBorderClassName: 'border-primary-500 border-opacity-20',
          headerTextClassName: 'text-neutral-black',
          orderSummary: {
            desktopHeaderSize: 'large-paragraph',
            card: orderSummaryCardStyles,
          },
        },
        reviewSection: {
          card: {
            textColorClassName: 'text-primary-500',
            borderColorClassName: 'border border-primary-300',
          },
          clinicalInfo: {
            buttonPalette: 'default',
            checkboxPalette: 'default',
            borderColorClassName: 'border-primary-500 border-opacity-20',
          },
          faq: {
            accordionPalette: 'default',
          },
        },
        addDetailsSection: {
          emailHintTextContainer: 'text-primary-400',
          backBtnPalette: 'default',
        },
        confirmSection: {
          buttons: {
            confirm: 'alternate',
            back: 'default',
            linkButton: 'default',
          },
          buttonSubText: theme.colors.neutral['700'],
          updateDetailsBorderColor: 'border-primary-300',
          updateDetailsDivideColor: 'divide-primary-300',
        },
        tierSelectionSection: {
          card: {
            textColorClassName: 'text-primary-500',
            highlightBgColorClassName: 'bg-secondary-lime-200',
          },
          backButtonPalette: 'default',
          borderColorClassName: 'border-primary-500 border-opacity-20',
        },
        receiptSection: {
          orderConfirmTitleColor: 'text-primary-500',
          tickIconColor: 'text-primary-500',
          tickIconBgColor: 'bg-secondary-lime-200',
          card: orderSummaryCardStyles,
          ratingTextColor: theme.colors.primary['500'],
          contactSupportTextColor: 'text-neutral-700',
        },
      }}
      thankYouIcon={<CircleTick />}
      downloadAppPrompt={
        <MobileAppPrompt
          appleClickElementName="purchaseActivationFlowAppleDownloadButton"
          googleClickElementName="purchaseActivationFlowGoogleDownloadButton"
        />
      }
    />
  );
}
