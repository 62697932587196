import {
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Box,
  Collapse,
} from '@chakra-ui/react';
import { AccordionPalette } from '@eucalyptusvc/design-system';
import { useThemeExtension } from '@eucalyptusvc/design-system/src/theme/shared';

type ExpandableSectionWithPreviewProps = {
  title: React.ReactNode;
  onToggle?: (isExpanded: boolean) => void;
  children: React.ReactNode;
};

export const ExpandableSectionWithPreview: React.FC<
  ExpandableSectionWithPreviewProps
> = ({ title, children, onToggle }) => {
  const themeStyle = useThemeExtension<AccordionPalette>(
    'accordion.defaultStyle',
    'default',
  );
  const onChange = (expandedIndex: number[]): void => {
    if (onToggle) {
      return onToggle(expandedIndex[0] === 0);
    }
  };
  return (
    <ChakraAccordion
      allowToggle
      variant={themeStyle}
      allowMultiple={true}
      onChange={onChange}
    >
      <AccordionItem
        style={{
          borderBottom: '0',
          paddingBottom: '0',
        }}
      >
        {({ isExpanded }) => {
          return (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    {title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <div
                style={{
                  transition: '-webkit-mask-image 0.4s',
                  WebkitMaskImage: !isExpanded
                    ? '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))'
                    : '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,1)))',
                }}
              >
                <Collapse animateOpacity startingHeight={88} in={isExpanded}>
                  <div className="pt-4 overflow-hidden">{children}</div>
                </Collapse>
              </div>
            </>
          );
        }}
      </AccordionItem>
    </ChakraAccordion>
  );
};
