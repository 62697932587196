import { FormattedMessage } from 'react-intl';
import { TrackerQuery } from '@customer-frontend/graphql-types';
import { Logger } from '@customer-frontend/logger';
import {
  ChartErrorBoundary,
  EnhancedTrackerChart,
} from '@customer-frontend/tracker';
import { Card, Typography } from '@eucalyptusvc/design-system';
import { primary500 } from '@eucalyptusvc/design-system/src/theme/juniper/palette';

export const DashboardTrackerChart = ({
  data,
  loading = false,
  logger,
}: {
  data?: TrackerQuery['tracker']['weightLoss'];
  loading?: boolean;
  logger: Logger;
}): React.ReactElement => {
  if (loading) {
    return (
      <Card>
        <div className="animate-pulse flex flex-col space-y-4">
          <div className="w-44 bg-gray-200 h-8 rounded-md" />
          <div className="w-28 bg-gray-200 h-16 rounded-md" />
          <div className="w-full bg-gray-200 h-72 rounded-md " />
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div className="pb-4">
        <Typography size="md" isBold color={primary500}>
          <FormattedMessage defaultMessage="Your weight journey" />
        </Typography>
      </div>
      <ChartErrorBoundary logger={logger}>
        <EnhancedTrackerChart
          weight={data?.weight ?? []}
          waist={data?.waist ?? []}
          momentum={data?.momentum ?? undefined}
        />
      </ChartErrorBoundary>
    </Card>
  );
};
