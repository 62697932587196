import { useMigrateSubscriptionsMutation } from '@customer-frontend/graphql-types';
import {
  getStripePaymentMethod,
  StripeDetailsForm,
} from '@customer-frontend/order';
import {
  useBackButtonBehaviour,
  useProfile,
} from '@customer-frontend/services';
import { Card, LoadingSpinner, Typography } from '@eucalyptusvc/design-system';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';

export const SubscriptionV2Upgrade: React.FC<{
  onCompleteRoute: string;
  textColor: string;
  profileRoute: string;
}> = ({ onCompleteRoute, profileRoute, textColor }) => {
  useBackButtonBehaviour(() => history.goBack());
  const { data, loading } = useProfile();

  const [migrateCustomerToPlatform] = useMigrateSubscriptionsMutation({
    refetchQueries: ['UseProfileSubscriptionsV2'],
  });
  const history = useHistory();

  const handleUpgrade = async (): Promise<void> => {
    await migrateCustomerToPlatform();
    history.replace(onCompleteRoute);
  };

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }
  if (!data?.profile?.needsSubscriptionMigration) {
    return <Redirect to={profileRoute} />;
  }
  const stripePaymentMethod = getStripePaymentMethod(
    data?.profile?.paymentMethods,
  );
  return (
    <Card>
      <div className="space-y-4">
        <Typography color={textColor} size="lg" isBold>
          Payment details
        </Typography>
        <Typography color={textColor} size="paragraph">
          {stripePaymentMethod ? (
            <>
              Please confirm the details below are correct. You can edit these
              details if they are either incorrect, or you have another
              preferred method.
            </>
          ) : (
            <>
              Please add your payment details to unlock new features for your
              subscription product.
            </>
          )}
          <Typography
            inheritColor={true}
            element="span"
            size="paragraph"
            isBold
          >
            {' '}
            You will not be charged until your next payment date
          </Typography>
        </Typography>

        <StripeDetailsForm
          stripePaymentMethod={stripePaymentMethod}
          onComplete={handleUpgrade}
        />
      </div>
    </Card>
  );
};
