import { Typography } from '@eucalyptusvc/design-system';
import React from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { routes } from '../../utils/routes';
import { FormattedMessage } from 'react-intl';

type TreatmentProps = {
  children: React.ReactNode;
};

type TabProps = {
  children: React.ReactNode;
  to: string;
  tabName: 'plan' | 'clinical' | 'faq';
};

export const Tab: React.FC<TabProps> = ({ to, children, tabName }) => {
  return (
    <NavLink
      replace
      to={to}
      activeClassName="border-primary-500 border-b-4"
      className="flex-1 flex items-center justify-center py-4 md:py-6 text-primary-500"
      isActive={(_, location) => {
        return location.pathname.includes(tabName);
      }}
    >
      {children}
    </NavLink>
  );
};

export const Treatment: React.FC<TreatmentProps> = ({ children }) => {
  const { treatmentId } = useParams<{ treatmentId: string }>();
  const location = useLocation();

  return (
    <div>
      <div className="w-full border-b bg-primary-100 border-transparent">
        <div className="max-w-screen-xl mx-auto flex px-4">
          <Tab to={routes.treatment.plan(treatmentId)} tabName="plan">
            <Typography size="xs" isBold={location.pathname.includes('plan')}>
              <FormattedMessage defaultMessage="Treatment Plan" />
            </Typography>
          </Tab>
          <Tab
            to={routes.treatment.practitionerLetter(treatmentId)}
            tabName="clinical"
          >
            <Typography
              size="xs"
              isBold={location.pathname.includes('clinical')}
            >
              <FormattedMessage defaultMessage="Clinical Information" />
            </Typography>
          </Tab>
          <Tab to={routes.treatment.faq(treatmentId)} tabName="faq">
            <Typography size="xs" isBold={location.pathname.includes('faq')}>
              <FormattedMessage defaultMessage="FAQs" />
            </Typography>
          </Tab>
        </div>
      </div>
      {children}
    </div>
  );
};
