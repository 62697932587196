import { routes } from 'utils/routes';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { Link } from 'react-router-dom';
import { Typography } from '@eucalyptusvc/design-system';
import { white } from '@eucalyptusvc/design-system/src/theme/shared';
import { FormattedMessage } from 'react-intl';

export const LoginAvatar = (): React.ReactElement => {
  return (
    <Link
      to={routes.login}
      className="flex items-center mx-2 sm:mx-3 p-3 h-full sm:h-auto"
    >
      <ProfileIcon role="presentation" />
      <div className="hidden sm:block ml-2">
        <Typography size="medium-paragraph" color={white}>
          <FormattedMessage
            defaultMessage="Log in"
            description="Navigation bar text prompting the user to log in"
          />
        </Typography>
      </div>
    </Link>
  );
};
