import { Card, Divider, Typography } from '@eucalyptusvc/design-system';
import { ReactComponent as ExclamationIcon } from '../assets/exclamation-icon.svg';
import { formatCentsToCurrency } from '@customer-frontend/order';
import {
  SubscriptionStatus,
  SubscriptionPlanInterval,
} from '@customer-frontend/graphql-types';
import {
  formatSubscriptionCadence,
  getBaseTextColorClassName,
  getGuaranteeListItemsFromPlanName,
} from '../utils';
import React from 'react';
import { RetryPaymentButton } from './retry-payment-button';
import { error500 } from '@eucalyptusvc/design-system/src/theme/shared';
import { getConfig } from '@customer-frontend/config';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { formatDate } from '@eucalyptusvc/lib-localization';

export type SubscriptionPlanCardProps = {
  id: string;
  name: string;
  photoUrl: string;
  price: number;
  interval: SubscriptionPlanInterval;
  intervalCount: number;
  description: string;
  nextPaymentDate?: string;
  includePromise?: boolean;
  status?: SubscriptionStatus;
  subscriptionId?: string;
} & (
  | {
      includePromise?: true;
      tickIcon: React.ReactElement;
    }
  | {
      includePromise?: false;
      tickIcon?: never;
    }
);

export const SubscriptionPlanCard: React.FC<SubscriptionPlanCardProps> = ({
  name,
  photoUrl,
  price,
  interval,
  intervalCount,
  description,
  includePromise,
  nextPaymentDate,
  tickIcon,
  status,
  subscriptionId,
}) => {
  const config = getConfig();
  const baseTextColor = getBaseTextColorClassName(config.brand);
  const guaranteeListItems = getGuaranteeListItemsFromPlanName(name);

  return (
    <Card>
      <div className="space-y-4">
        <div className="flex rounded-lg overflow-hidden w-60 h-auto">
          <img src={photoUrl} alt="" />
        </div>

        <div className="space-y-4">
          <Typography size="lg" isBold>
            {name}
          </Typography>
          <div className="flex items-center space-x-1">
            <Typography size="landing-paragraph" element="p">
              {formatCentsToCurrency(price)}
            </Typography>
            <Typography size="medium-paragraph">
              /{formatSubscriptionCadence(interval, intervalCount)}
            </Typography>
          </div>
        </div>

        <Typography size="medium-paragraph" color={baseTextColor}>
          {description}
        </Typography>

        {nextPaymentDate && status !== 'PROCESSING' && (
          <>
            <Typography size="paragraph" isBold>
              Next payment
            </Typography>
            <Typography size="paragraph" element="span">
              {formatDate(
                mapBrandToAdaptersBrand(config.brand),
                nextPaymentDate,
                {
                  weekday: 'long',
                  day: 'numeric',
                  month: 'long',
                },
              )}
            </Typography>
          </>
        )}

        {status === 'PROCESSING' && (
          <Typography size="paragraph" isBold>
            Your order is currently being processed
          </Typography>
        )}

        {status === 'PAYMENT_FAILED' && subscriptionId && (
          <>
            <Divider />
            <div className="flex flex-row items-center mb-1">
              <ExclamationIcon className="mr-2" />
              <Typography size="medium-paragraph" color={error500} isBold>
                Payment Failed
              </Typography>
            </div>
            <Typography size="medium-paragraph">
              We recommend adding funds to your card and retrying your payment.
              Alternatively you can edit your card details first via your
              profile page before retrying your order.
            </Typography>
            <RetryPaymentButton subscriptionId={subscriptionId} />
          </>
        )}

        {includePromise && (
          <>
            <Divider />
            <div className="space-y-4 py-2">
              <Typography size="md" isBold>
                Our promise to you...
              </Typography>
              <ul className="space-y-4">
                {guaranteeListItems.map((item) => (
                  <li
                    key={item.headline}
                    className="flex items-center space-x-4"
                  >
                    <div className="flex-shrink-0">{tickIcon}</div>
                    <div className="space-y-1">
                      <Typography size="medium-paragraph" isBold>
                        {item.headline}
                      </Typography>
                      <Typography size="medium-paragraph">
                        {item.byline}
                      </Typography>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};
