import React, { ReactElement } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  RouteProps,
} from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import {
  PrivateRoute,
  PrivateRouteProps,
  RouteTracker,
  ScrollToTop,
} from '@customer-frontend/route';
import { PersistedDiscountFromURLProvider } from '@customer-frontend/order';
import { WeightDashboardThemeProvider } from '@customer-frontend/weight-dashboard';
import { TrackerThemeProvider } from '@customer-frontend/tracker';
import {
  EducationThemeProvider,
  NextReadingRedirect,
} from '@customer-frontend/education';
import { routes } from 'utils/routes';
import { NavBarLayout } from 'components/navigation/navbar-layout';
import ProfileLayout from './profile/layout';
import { OfferingLayout } from '@customer-frontend/page-templates';
import { CookieConsentManager } from '../components/navigation/consent/cookie-consent-manager';
import { PaymentTabs } from './consultation/upfront-payment/payment-tabs';
import ActivatePurchaseFlow from './consultation/flexiplans/activate-purchase-flow';
import { getConfig } from '@customer-frontend/config';
import { Treatment } from './treatment';
import { environment } from 'environment';
import {
  useFeatureFlagBoolean,
  useFeatureFlagClient,
} from '@customer-frontend/feature-flags';
import { LoadingSpinner } from '@eucalyptusvc/design-system';
import { DownloadAppBannerContextProvider } from '@customer-frontend/services';
import { useFlexiEnabled } from '@customer-frontend/utils';
import { ImpersonatingUserBanner } from '@customer-frontend/auth';

const Throw = React.lazy(() => import('./throw'));
const Addons = React.lazy(() => import('./consultation/add-ons'));
const Account = React.lazy(() => import('./profile/account'));
const Profile = React.lazy(() => import('./profile/main'));
const ProfileConfirmPaymentDetails = React.lazy(
  () => import('./profile/account/confirm-payment-details'),
);
const Login = React.lazy(() => import('./auth/login'));
const Reset = React.lazy(() => import('./auth/reset'));
const ForgetPassword = React.lazy(() => import('./auth/forget-password'));
const Signup = React.lazy(() => import('./auth/signup'));
const Start = React.lazy(() => import('./start/start'));
const StartWelcome = React.lazy(() => import('./start/start-welcome'));
const StartSignup = React.lazy(() => import('./start/start-signup'));
const StartConfirm = React.lazy(() => import('./start/start-confirm'));
const ContinueToProfile = React.lazy(() => import('./start/continue'));
const Quiz = React.lazy(() => import('./quiz/quiz'));
const QuizComplete = React.lazy(() => import('./quiz/quiz-complete'));
const QuizSummary = React.lazy(() => import('./quiz/quiz-summary'));
const QuizStartGeneral = React.lazy(() => import('./quiz/quiz-start-general'));
const QuizCompleteGeneral = React.lazy(
  () => import('./quiz/quiz-complete-general'),
);
const ConsultationCollectAddress = React.lazy(
  () => import('./consultation/collect-address'),
);
const ConsultationConfirmAnswers = React.lazy(
  () => import('./consultation/confirm-answers'),
);
const ConsultationCreateAccount = React.lazy(
  () => import('./consultation/create-account'),
);
const ConsultationPay = React.lazy(() => import('./consultation/consult-pay'));
const ConsultationConfirmation = React.lazy(
  () => import('./consultation/confirmation'),
);
const ConsultationChat = React.lazy(
  () => import('./consultation/consultation-chat'),
);
const UpfrontPaymentPage = React.lazy(
  () => import('./consultation/upfront-payment'),
);
const DoctorsNote = React.lazy(() => import('./consultation/doctors-note'));
const ConsultationTreatmentBreakdown = React.lazy(
  () => import('./consultation/treatment-breakdown/index'),
);
const OrderConfirmation = React.lazy(() => import('./consultation/complete'));
const ConsultationPlan = React.lazy(() => import('./consultation/plan'));
const PharmacySafetyInformation = React.lazy(
  () => import('./consultation/pharmacy-safety-information'),
);
const Shipping = React.lazy(() => import('./consultation/shipping'));
const Reason = React.lazy(() => import('./consultation/reason'));
const Review = React.lazy(() => import('./consultation/review'));
const TreatmentPlan = React.lazy(() => import('./treatment/plan/index'));
const TreatmentFaq = React.lazy(() => import('./treatment/faq/index'));
const TreatmentClinical = React.lazy(
  () => import('./treatment/clinical/index'),
);
const TreatmentPharmacyInformation = React.lazy(
  () => import('./treatment/clinical/pharmacy-information/index'),
);
const TreatmentPractitioner = React.lazy(
  () => import('./treatment/clinical/practitioner/index'),
);
const OfferingPlan = React.lazy(() => import('./offering/plan'));
const OfferingSwitch = React.lazy(() => import('./offering/switch'));
const OfferingSwitchReceipt = React.lazy(
  () => import('./offering/switch-receipt'),
);
const OfferingNotes = React.lazy(() => import('./offering/notes'));
const OfferingFaq = React.lazy(() => import('./offering/faq'));
const FollowUp = React.lazy(() => import('./consultation/follow-up'));
const ConfirmPathology = React.lazy(
  () => import('./consultation/confirm-pathology'),
);
const WeightDashboard = React.lazy(() => import('./weight-loss/dashboard'));
const SunsetBpDashboard = React.lazy(
  () => import('./weight-loss/sunset-bp-dashboard'),
);
const WeightLearn = React.lazy(() => import('./weight-loss/learn'));
const WeightTrackerConfirmation = React.lazy(
  () => import('./weight-loss/tracker-confirmation'),
);
const WeightFaq = React.lazy(() => import('./weight-loss/faq'));
const EducationContent = React.lazy(() => import('./education/content'));
const ErrorComponent = React.lazy(() => import('./error'));
const ConsultConfirmPaymentDetails = React.lazy(
  () => import('./consultation/confirm-payment-details'),
);
const PurchaseOfferingsIntentHandler = React.lazy(
  () => import('./purchase-offerings-intent-handler-wrapper'),
);
const ConfirmOrderPaymentDetails = React.lazy(
  () => import('./consultation/confirm-order-payment-details'),
);
const RejectedScreen = React.lazy(() => import('./quiz/rejected-screen'));

const Subscriptions = React.lazy(() => import('./profile/subscriptions'));

const SubscriptionV2PlanPayment = React.lazy(
  () => import('./subscription-v2/plan-payment'),
);
const SubscriptionV2Confirm = React.lazy(
  () => import('./subscription-v2/confirm'),
);
const SubscriptionV2Manage = React.lazy(
  () => import('./subscription-v2/manage'),
);
const SubscriptionV2Cancelled = React.lazy(
  () => import('./subscription-v2/cancelled'),
);
const SubscriptionV2Upgrade = React.lazy(
  () => import('./subscription-v2/upgrade'),
);
const SubscriptionV2UpgradeSuccess = React.lazy(
  () => import('./subscription-v2/upgrade-success'),
);

const CollectMedicare = React.lazy(
  () => import('./consultation/collect-medicare'),
);
const AlternativeMedicationsFaq = React.lazy(
  () => import('./profile/faq/alternative-medications'),
);
const SaxendaDosageCapFaq = React.lazy(
  () => import('./profile/faq/saxenda-dosage-cap'),
);
const Program = React.lazy(() => import('./consultation/program'));
const InitialPurchaseReview = React.lazy(
  () => import('./consultation/flexiplans/initial-purchase-review'),
);
const InitialPurchaseConfirm = React.lazy(
  () => import('./consultation/flexiplans/initial-purchase-confirm/default'),
);
const InitialPurchaseConfirmV2 = React.lazy(
  () => import('./consultation/flexiplans/initial-purchase-confirm/v2'),
);
const InitialPurchaseConfirmJP = React.lazy(
  () => import('./consultation/flexiplans/initial-purchase-confirm/jp'),
);
const PractitionerPhoneCall = React.lazy(
  () => import('./consultation/practitioner-phone-call'),
);
const SchedulePhoneCall = React.lazy(
  () => import('./consultation/schedule-phone-call'),
);
const FlexiplansDev = React.lazy(() => import('./consultation/flexiplans/dev'));
const MobileLinkHandler = React.lazy(() => import('./mobile-link-handler'));
const SubstitutePurchaseReview = React.lazy(
  () => import('./consultation/flexiplans/substitute-purchase-review-page'),
);
const SubstitutePurchaseConfirm = React.lazy(
  () => import('./consultation/flexiplans/substitute-purchase-confirm-page'),
);
const FollowUpOrReviewPurchaseReview = React.lazy(
  () =>
    import(
      './consultation/flexiplans/follow-up-or-review-purchase-review-page'
    ),
);
const FollowUpOrReviewPurchaseConfirm = React.lazy(
  () =>
    import(
      './consultation/flexiplans/follow-up-or-review-purchase-confirm-page'
    ),
);
const OrderConfirmed = React.lazy(
  () => import('./consultation/flexiplans/order-confirmed'),
);
const SafetyInformation = React.lazy(
  () => import('./consultation/flexiplans/safety-information'),
);
const DoctorLetter = React.lazy(
  () => import('./consultation/flexiplans/doctor-letter'),
);
const MedicationPreference = React.lazy(
  () => import('./consultation/medication-preference'),
);
const Purchase = React.lazy(() => import('./purchase/checkout'));
const PurchaseConfirmation = React.lazy(
  () => import('./purchase/confirmation'),
);
const CallConfirm = React.lazy(
  () => import('./consultation/practitioner-call-confirmed'),
);

function SuspenseRoute(props: RouteProps): React.ReactElement {
  return (
    <React.Suspense
      fallback={
        <div className="flex justify-center p-5">
          <LoadingSpinner />
        </div>
      }
    >
      <Route {...props} />
    </React.Suspense>
  );
}

function SuspensePrivateRoute(props: PrivateRouteProps): React.ReactElement {
  return (
    <React.Suspense
      fallback={
        <div className="flex justify-center p-5">
          <LoadingSpinner />
        </div>
      }
    >
      <PrivateRoute {...props} />
    </React.Suspense>
  );
}

const config = getConfig();

function Routes(): ReactElement {
  const enableFlexiplansOtc = useFeatureFlagBoolean(
    'ENABLE_FLEXIPLANS_OTC_PURCHASE',
  );
  const removeWelcomePage = useFeatureFlagBoolean('XP_REMOVE_WELCOME_PAGE');

  return (
    <BrowserRouter>
      <CompatRouter>
        <RouteTracker />
        <ScrollToTop />
        <CookieConsentManager />
        <ImpersonatingUserBanner />
        <PersistedDiscountFromURLProvider>
          <DownloadAppBannerContextProvider>
            <NavBarLayout>
              <Switch>
                <SuspenseRoute path={routes.login}>
                  <Login />
                </SuspenseRoute>
                <SuspenseRoute path={routes.forgotPassword}>
                  <ForgetPassword />
                </SuspenseRoute>
                <SuspenseRoute path={routes.reset}>
                  <Reset />
                </SuspenseRoute>
                <SuspenseRoute path={routes.signup}>
                  <Signup />
                </SuspenseRoute>
                <SuspenseRoute path={routes.start} exact>
                  <Start />
                </SuspenseRoute>
                <SuspenseRoute path={routes.startWelcome}>
                  {removeWelcomePage ? (
                    <Redirect
                      to={{
                        pathname: routes.startSignup,
                        search: window.location.search,
                      }}
                    />
                  ) : (
                    <StartWelcome />
                  )}
                </SuspenseRoute>
                <SuspenseRoute path={routes.startSignup}>
                  <StartSignup />
                </SuspenseRoute>
                <SuspenseRoute path={routes.mobileLink}>
                  <MobileLinkHandler />
                </SuspenseRoute>
                {enableFlexiplansOtc && (
                  <SuspenseRoute exact path="/purchase">
                    <Purchase />
                  </SuspenseRoute>
                )}
                {enableFlexiplansOtc && (
                  <SuspensePrivateRoute
                    exact
                    path="/purchase/:purchaseGroupId/confirmation"
                  >
                    <PurchaseConfirmation />
                  </SuspensePrivateRoute>
                )}

                <SuspenseRoute path="/subscription-plan/:subscriptionPlanId">
                  <SubscriptionV2PlanRoutes />
                </SuspenseRoute>
                <SuspensePrivateRoute path="/subscription/:subscriptionId">
                  <SubscriptionV2Manage />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/subscriptions/cancelled">
                  <SubscriptionV2Cancelled />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute
                  path={`/handle-purchase-offerings-intent`}
                >
                  <PurchaseOfferingsIntentHandler />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path={routes.subscriptionV2.upgrade}>
                  <SubscriptionV2Upgrade />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute
                  path={routes.subscriptionV2.upgradeSuccess}
                >
                  <SubscriptionV2UpgradeSuccess />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute
                  path={routes.profileAccountConfirmPaymentDetails}
                >
                  <ProfileConfirmPaymentDetails />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path={routes.startConfirm}>
                  <StartConfirm />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path={routes.continueToProfile}>
                  <ContinueToProfile />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path={routes.profile}>
                  <ProfileLayout>
                    <Switch>
                      <SuspenseRoute path={'/profile/treatment'}>
                        <Redirect
                          to={{
                            pathname: routes.profile,
                          }}
                        />
                      </SuspenseRoute>
                      <SuspenseRoute path={routes.profileAccount}>
                        <Account />
                      </SuspenseRoute>
                      <SuspenseRoute path={routes.alternativeMedicationsFaq}>
                        <AlternativeMedicationsFaq />
                      </SuspenseRoute>
                      <SuspenseRoute path={routes.saxendaDosageCapFaq}>
                        <SaxendaDosageCapFaq />
                      </SuspenseRoute>
                      <SuspenseRoute>
                        <Profile />
                      </SuspenseRoute>
                    </Switch>
                  </ProfileLayout>
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/consultation/:consultationId">
                  <ConsultationRoutes />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/treatment/:treatmentId">
                  <TreatmentRoutes />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/offering/:purchaseId/switch">
                  <OfferingSwitch />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/offering/:purchaseId/switch-receipt">
                  <OfferingSwitchReceipt />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/offering/:purchaseId">
                  <OfferingsRoutes />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/quiz">
                  <QuizRoutes />
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/weight-loss">
                  <WeightDashboardThemeProvider brand={config.brand}>
                    <TrackerThemeProvider brand={config.brand}>
                      <EducationThemeProvider brand={config.brand}>
                        <WeightLossRoutes />
                      </EducationThemeProvider>
                    </TrackerThemeProvider>
                  </WeightDashboardThemeProvider>
                </SuspensePrivateRoute>
                <SuspensePrivateRoute path="/education/programs/:programId/next-reading">
                  <NextReadingRedirect
                    buildEducationReadingProgramRoute={(
                      readingId: string,
                      programId: string,
                    ) => routes.education.reading(readingId, { programId })}
                    dashboardRoute={routes.weightLoss.dashboard}
                  />
                </SuspensePrivateRoute>
                <SuspenseRoute path="/education/readings/:readingId">
                  <EducationContent />
                </SuspenseRoute>
                <SuspenseRoute path="/" exact>
                  <Redirect
                    to={{
                      pathname: routes.profile,
                    }}
                  />
                </SuspenseRoute>
                {config.otcSubscriptions && (
                  <SuspensePrivateRoute
                    exact
                    path={routes.profileSubscriptions}
                  >
                    <Subscriptions />
                  </SuspensePrivateRoute>
                )}
                <SuspensePrivateRoute path="/call/:pracBookingId/confirmation">
                  <CallConfirm />
                </SuspensePrivateRoute>
                {!environment.isProd && (
                  <SuspensePrivateRoute exact path="/dev/anytime-purchase">
                    <FlexiplansDev />
                  </SuspensePrivateRoute>
                )}
                <SuspenseRoute exact path="/throw/:message">
                  <Throw />
                </SuspenseRoute>
                <SuspenseRoute>
                  <ErrorComponent />
                </SuspenseRoute>
              </Switch>
            </NavBarLayout>
          </DownloadAppBannerContextProvider>
        </PersistedDiscountFromURLProvider>
      </CompatRouter>
    </BrowserRouter>
  );
}

function QuizRoutes(): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <SuspenseRoute path={`${path}/start/:quizCode`}>
        <QuizStartGeneral />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/complete/:quizCode`}>
        <QuizCompleteGeneral />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/:quizApplicationId/complete`}>
        <QuizComplete />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/:quizApplicationId/summary`}>
        <QuizSummary />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/:quizApplicationId/rejected`}>
        <RejectedScreen />
      </SuspenseRoute>
      <SuspenseRoute
        path={[
          `${path}/:quizApplicationId/question/:questionId`,
          `${path}/:quizApplicationId`,
        ]}
      >
        <Quiz />
      </SuspenseRoute>
    </Switch>
  );
}

function OfferingsRoutes(): React.ReactElement {
  const { path, params } = useRouteMatch<{ purchaseId: string }>();
  const { purchaseId } = params;

  return (
    <OfferingLayout
      routes={{
        offering: {
          plan: routes.offering.plan(purchaseId),
          notes: routes.offering.notes(purchaseId),
          faq: routes.offering.faq(purchaseId),
        },
        profile: routes.profile,
      }}
    >
      <Switch>
        <SuspenseRoute exact path={`${path}/notes`} component={OfferingNotes} />
        <SuspenseRoute exact path={`${path}/faq`} component={OfferingFaq} />
        <SuspenseRoute component={OfferingPlan} /> {/* Fallback, and /plan */}
      </Switch>
    </OfferingLayout>
  );
}

function TreatmentRoutes(): React.ReactElement {
  const { path } = useRouteMatch();
  return (
    <Treatment>
      <Switch>
        <SuspenseRoute path={`${path}/clinical`}>
          <TreatmentClinical>
            <Switch>
              <SuspenseRoute exact path={`${path}/clinical/pharmacy`}>
                <TreatmentPharmacyInformation />
              </SuspenseRoute>
              <SuspenseRoute>
                <TreatmentPractitioner />
              </SuspenseRoute>
            </Switch>
          </TreatmentClinical>
        </SuspenseRoute>

        <SuspenseRoute exact path={`${path}/faq`} component={TreatmentFaq} />
        <SuspenseRoute component={TreatmentPlan} />
      </Switch>
    </Treatment>
  );
}

function ConsultationRoutes(): React.ReactElement {
  const { path, params } = useRouteMatch<{ consultationId: string }>();
  const flexiEnabled = useFlexiEnabled(params.consultationId);
  const featureFlagClient = useFeatureFlagClient();
  const xpRemoveProgramPage = featureFlagClient.getBoolean(
    'XP_REMOVE_PROGRAM_PAGE',
  );
  const xpInitialPurchaseConfirmV2Available =
    featureFlagClient.getMultivariate('INITIAL_PURCHASE_CONFIRM_V2', {
      disableExposureTracking: true,
    }) === 'variation';

  return (
    <Switch>
      <SuspensePrivateRoute path={`${path}/collect-medicare`}>
        <CollectMedicare />
      </SuspensePrivateRoute>
      <SuspenseRoute path={`${path}/collect-address`}>
        <ConsultationCollectAddress />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/confirm-answers`}>
        <ConsultationConfirmAnswers />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/create-account`}>
        <ConsultationCreateAccount />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/confirmation`}>
        <ConsultationConfirmation />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/chat`}>
        <ConsultationChat />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/complete`}>
        <OrderConfirmation />
      </SuspenseRoute>
      {flexiEnabled ? (
        <SuspenseRoute path={`${path}/doctors-note`}>
          <DoctorLetter />
        </SuspenseRoute>
      ) : (
        <SuspenseRoute path={`${path}/doctors-note`}>
          <DoctorsNote />
        </SuspenseRoute>
      )}
      <SuspenseRoute path={`${path}/treatment-breakdown`}>
        <ConsultationTreatmentBreakdown />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/plan`}>
        <ConsultationPlan />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/pharmacyInformation`}>
        <PharmacySafetyInformation />
      </SuspenseRoute>
      <SuspensePrivateRoute path={`${path}/add`}>
        <Addons />
      </SuspensePrivateRoute>
      <SuspenseRoute path={`${path}/shipping`}>
        <Shipping />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/reason`}>
        <Reason />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/review`}>
        <Review />
      </SuspenseRoute>
      <SuspensePrivateRoute path={`${path}/follow-up`}>
        <FollowUp />
      </SuspensePrivateRoute>
      <SuspensePrivateRoute path={`${path}/confirm-pathology`}>
        <ConfirmPathology />
      </SuspensePrivateRoute>
      <SuspenseRoute path={`${path}/review-substitution`}>
        <SubstitutePurchaseReview />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/confirm-substitution`}>
        <SubstitutePurchaseConfirm />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/review-follow-up-or-review`}>
        <FollowUpOrReviewPurchaseReview />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/confirm-follow-up-or-review`}>
        <FollowUpOrReviewPurchaseConfirm />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/order-confirmed`}>
        <OrderConfirmed />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/offering-safety-information`}>
        <SafetyInformation />
      </SuspenseRoute>

      {xpRemoveProgramPage && (
        <Redirect from={`${path}/programme`} to={`${path}/payment`} />
      )}

      {xpRemoveProgramPage && (
        <Redirect from={`${path}/program`} to={`${path}/payment`} />
      )}

      {flexiEnabled ? (
        <SuspenseRoute path={`${path}/programme`}>
          <InitialPurchaseReview />
        </SuspenseRoute>
      ) : (
        <SuspenseRoute path={`${path}/programme`}>
          <UpfrontPaymentPage activeTab={PaymentTabs.PROGRAMME} />
        </SuspenseRoute>
      )}

      <SuspenseRoute path={`${path}/program`}>
        <Program />
      </SuspenseRoute>

      <SuspenseRoute path={`${path}/phone-call`}>
        <PractitionerPhoneCall />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/schedule-call`}>
        <SchedulePhoneCall />
      </SuspenseRoute>
      {flexiEnabled && config.brand === 'juniper-jp' && (
        <SuspenseRoute path={`${path}/payment`}>
          <InitialPurchaseConfirmJP />
        </SuspenseRoute>
      )}
      {flexiEnabled && xpInitialPurchaseConfirmV2Available && (
        <SuspenseRoute path={`${path}/payment`}>
          <InitialPurchaseConfirmV2 />
        </SuspenseRoute>
      )}
      {flexiEnabled && !xpInitialPurchaseConfirmV2Available && (
        <SuspenseRoute path={`${path}/payment`}>
          <InitialPurchaseConfirm />
        </SuspenseRoute>
      )}

      <SuspenseRoute path={`${path}/payment`}>
        <UpfrontPaymentPage
          activeTab={PaymentTabs.PAYMENT}
          showTabs={!xpRemoveProgramPage}
        />
      </SuspenseRoute>
      {!flexiEnabled && (
        <SuspenseRoute path={`${path}/consult-pay`}>
          <ConsultationPay />
        </SuspenseRoute>
      )}
      <SuspenseRoute path={`${path}/medication-preference`}>
        <MedicationPreference />
      </SuspenseRoute>
      {!flexiEnabled && (
        <SuspenseRoute path={`${path}/confirm-payment-details`}>
          <ConsultConfirmPaymentDetails />
        </SuspenseRoute>
      )}
      {!flexiEnabled && (
        <SuspenseRoute path={`${path}/confirm-order-payment-details`}>
          <ConfirmOrderPaymentDetails />
        </SuspenseRoute>
      )}
      <SuspensePrivateRoute path={`${path}/activate-purchase`}>
        <ActivatePurchaseFlow />
      </SuspensePrivateRoute>
      <SuspensePrivateRoute exact path={path}>
        <Redirect to={routes.profile} />
      </SuspensePrivateRoute>
    </Switch>
  );
}

function WeightLossRoutes(): React.ReactElement {
  const { path } = useRouteMatch();

  const sunsetBpDashboard = useFeatureFlagBoolean('SUNSET_BP_DASHBOARD');
  if (sunsetBpDashboard) {
    return <SunsetBpDashboard />;
  }

  return (
    <Switch>
      <SuspenseRoute path={`${path}/dashboard`}>
        <WeightDashboard />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/confirmation`}>
        <WeightTrackerConfirmation />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/learn`}>
        <WeightLearn />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/faq`}>
        <WeightFaq />
      </SuspenseRoute>
      <SuspenseRoute>
        <ErrorComponent />
      </SuspenseRoute>
    </Switch>
  );
}

function SubscriptionV2PlanRoutes(): React.ReactElement {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <SuspenseRoute path={`${path}/payment`}>
        <SubscriptionV2PlanPayment />
      </SuspenseRoute>
      <SuspenseRoute path={`${path}/confirm`}>
        <SubscriptionV2Confirm />
      </SuspenseRoute>
    </Switch>
  );
}

export default Routes;
