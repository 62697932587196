import {
  DiscountCodeFormFragment,
  useAutoAddOtcToTreatmentMutation,
  useRemoveOtcLineItemMutation,
  useRemoveOtcProductFromTreatmentMutation,
  useUpdateLineItemMutation,
} from '@customer-frontend/graphql-types';
import { useEffect, useState, useCallback, useRef } from 'react';
import { ActionModal, Typography } from '@eucalyptusvc/design-system';
import {
  calculateDiscountedPriceForProduct,
  formatCentsToCurrency,
} from '../../logic';
import { useIntl } from 'react-intl';

interface AutoAddDiscountedProductsProps {
  orderId?: string;
  treatmentId: string;
  productsInOrder: {
    id: string;
  }[];
  code?: DiscountCodeFormFragment;
  onProductRemoved: () => void;
  onDiscountApplied?: (data: {
    discountCode: DiscountCodeFormFragment;
  }) => void;
  loading?: boolean;
}

export const AutoAddDiscountedProduct: React.FunctionComponent<
  AutoAddDiscountedProductsProps
> = ({
  orderId,
  treatmentId,
  productsInOrder,
  code,
  onDiscountApplied,
  onProductRemoved,
  loading = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [addedProductId, setAddedProductId] = useState<string>();
  const [
    autoAddOtcToTreatmentMutation,
    { loading: addOtcScheduleLoading, data: addOtcScheduleData },
  ] = useAutoAddOtcToTreatmentMutation();
  const [updateLineItemMutation, { loading: updateLineItemLoading }] =
    useUpdateLineItemMutation();
  const [removeLineItemMutation, { loading: removeLineItemMutationLoading }] =
    useRemoveOtcLineItemMutation();
  const [
    removeOtcProductFromTreatment,
    { loading: removeOtcProductFromTreatmentLoading },
  ] = useRemoveOtcProductFromTreatmentMutation({
    onCompleted() {
      setIsOpen(false);
    },
  });

  const productsInOrderRef = useRef(productsInOrder);

  useEffect(() => {
    productsInOrderRef.current = productsInOrder;
  }, [productsInOrder]);

  useEffect(() => {
    if (code) {
      // Only try auto add for codes with 1 product
      if (code.products.length !== 1) {
        if (onDiscountApplied) {
          onDiscountApplied({ discountCode: code });
        }
        return;
      }
      const [codeProduct] = code.products;

      if (productsInOrderRef.current.some((p) => p.id === codeProduct.id)) {
        if (onDiscountApplied) {
          onDiscountApplied({ discountCode: code });
        }
        return;
      }

      const variantId = codeProduct.variants[0]?.id;
      if (!variantId) {
        // Should never get here...
        // Just don't try auto add anything...
        if (onDiscountApplied) {
          onDiscountApplied({ discountCode: code });
        }
        return;
      }

      const quantity = 1;

      const addProduct = async (): Promise<void> => {
        const promises = [
          autoAddOtcToTreatmentMutation({
            variables: {
              id: treatmentId,
              productId: codeProduct.id,
              quantity,
              cadence: code.stages.includes('RECURRING_ORDER_DISCOUNT')
                ? 'RECURRING'
                : 'ONE_TIME',
            },
          }),
        ];
        if (orderId) {
          promises.push(
            updateLineItemMutation({
              variables: {
                orderId,
                quantity,
                variantId,
              },
            }),
          );
        }
        setAddedProductId(codeProduct.id);
        setIsOpen(true);
        await Promise.all(promises);
        if (onDiscountApplied) {
          onDiscountApplied({ discountCode: code });
        }
      };

      addProduct();
    }
  }, [
    productsInOrderRef,
    code,
    autoAddOtcToTreatmentMutation,
    treatmentId,
    updateLineItemMutation,
    orderId,
    onDiscountApplied,
  ]);

  const addedProductData =
    addOtcScheduleData?.upsertOtcProductToTreatment?.otcSchedules.find(
      (otcSchedule) => otcSchedule.product.id === addedProductId,
    )?.product;

  const onRemoveFromCart = useCallback(async () => {
    const promises = [
      removeOtcProductFromTreatment({
        variables: {
          id: treatmentId,
          productId: addedProductId ?? '',
        },
      }),
    ];
    if (orderId) {
      promises.push(
        removeLineItemMutation({
          variables: {
            orderId,
            variantId: addedProductData?.variants[0]?.id ?? '',
          },
        }),
      );
    }
    await Promise.all(promises);
    onProductRemoved();

    setIsOpen(false);
  }, [
    addedProductData?.variants,
    addedProductId,
    orderId,
    removeOtcProductFromTreatment,
    treatmentId,
    removeLineItemMutation,
    onProductRemoved,
  ]);
  const { formatMessage } = useIntl();

  const modalLoading =
    loading ||
    !addedProductData ||
    addOtcScheduleLoading ||
    updateLineItemLoading;

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={formatMessage({
        defaultMessage:
          "Success! We've added this item to your cart and applied your discount",
      })}
      primaryAction={{
        label: formatMessage({
          defaultMessage: 'Got it',
          description:
            'Modal button text to acknowledge that the item was added to the cart',
        }),
        onClick() {
          setIsOpen(false);
        },
      }}
      secondaryAction={{
        label: formatMessage({
          defaultMessage: 'Remove from cart',
        }),
        onClick: onRemoveFromCart,
        isLoading:
          removeOtcProductFromTreatmentLoading || removeLineItemMutationLoading,
      }}
      actionRequired
      contentsLoading={modalLoading}
    >
      <div className="space-y-5">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center space-x-2">
            <img
              src={addedProductData?.photo?.url}
              className="w-20 rounded-md"
            />
            <Typography size="medium-paragraph">
              {addedProductData?.name}
            </Typography>
          </div>
          <div className="flex flex-row items-center flex-none space-x-2">
            <Typography size="medium-paragraph">
              <span className="line-through">
                {formatCentsToCurrency(
                  addedProductData?.variants[0]?.price ?? 0,
                  { includeDecimals: true },
                )}
              </span>
            </Typography>
            <Typography size="medium-paragraph">
              {formatCentsToCurrency(
                calculateDiscountedPriceForProduct({
                  product: {
                    id: addedProductData?.id ?? '',
                    price: addedProductData?.variants[0]?.price ?? 0,
                  },
                  discount: code,
                }).totalPrice,
                { includeDecimals: true },
              )}
            </Typography>
          </div>
        </div>
      </div>
    </ActionModal>
  );
};
