import { useEnvironment } from '@customer-frontend/environment';
import { Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';

interface UseTermsAndConditionsProps {
  termsAndConditionsPath: string;
}

export const useTermsAndConditionsLink = ({
  termsAndConditionsPath,
}: UseTermsAndConditionsProps): string => {
  const environment = useEnvironment();

  return `${environment.landingPageUrl}${termsAndConditionsPath}`;
};

export interface TermsAndConditionsProps {
  termsAndConditionsPath: string;
  privacyPolicyPath: string;
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  termsAndConditionsPath,
  privacyPolicyPath,
}) => {
  const environment = useEnvironment();
  const termsAndConditionsLink = useTermsAndConditionsLink({
    termsAndConditionsPath,
  });

  return (
    <Typography size="paragraph">
      <FormattedMessage
        defaultMessage="By clicking below you confirm you have read and agree to our <tc>Terms & Conditions</tc> and <pp>Privacy Policy</pp>."
        values={{
          tc: (chunks) => (
            <a
              href={termsAndConditionsLink}
              className="text-link font-semibold"
              {...(environment.appEnv === 'development'
                ? { tabIndex: -1 }
                : {})}
            >
              {chunks}
            </a>
          ),
          pp: (chunks) => (
            <a
              href={`${environment.landingPageUrl}${privacyPolicyPath}`}
              className="text-link font-semibold"
              {...(environment.appEnv === 'development'
                ? { tabIndex: -1 }
                : {})}
            >
              {chunks}
            </a>
          ),
        }}
      />
    </Typography>
  );
};

export default TermsAndConditions;
