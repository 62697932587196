import { useNextUnreadEducationReadingQuery } from '@customer-frontend/graphql-types';
import { LoadingSpinner } from '@eucalyptusvc/design-system';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

type NextReadingRedirectProps = {
  buildEducationReadingProgramRoute: (
    readingId: string,
    programId: string,
  ) => string;
  dashboardRoute: string;
};

export function NextReadingRedirect({
  buildEducationReadingProgramRoute,
  dashboardRoute,
}: NextReadingRedirectProps): React.ReactElement {
  const { programId } = useParams<{ programId: string }>();
  const { data, loading } = useNextUnreadEducationReadingQuery({
    variables: { id: programId },
  });
  const nextUnreadReadingId = data?.educationProgram?.nextUnreadReading?.id;

  if (!loading && nextUnreadReadingId) {
    return (
      <Redirect
        to={buildEducationReadingProgramRoute(nextUnreadReadingId, programId)}
      />
    );
  } else if (!loading && !nextUnreadReadingId) {
    return <Redirect to={dashboardRoute} />;
  } else {
    return (
      <div className="flex justify-center p-10">
        <LoadingSpinner palette="default" />
      </div>
    );
  }
}
