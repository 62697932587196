import {
  SubscriptionStatus,
  SubscriptionPlanInterval,
} from '@customer-frontend/graphql-types';
import { Card, Typography } from '@eucalyptusvc/design-system';
import { error500, black } from '@eucalyptusvc/design-system/src/theme/shared';
import { formatCentsToCurrency } from '@customer-frontend/order';
import { ReactComponent as ExclamationIcon } from '../assets/exclamation-icon.svg';
import { formatSubscriptionCadence, getBaseTextColorClassName } from '../utils';
import { Logger } from '@customer-frontend/logger';
import { getConfig } from '@customer-frontend/config';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { formatDate } from '@eucalyptusvc/lib-localization';

type SubscriptionCardProps = {
  id: string;
  name: string;
  photoUrl: string;
  price: number;
  interval: SubscriptionPlanInterval;
  intervalCount: number;
  nextPaymentDate?: string;
  status: SubscriptionStatus;
  logger: Logger;
  children: React.ReactNode;
};

const SubscriptionStatusDisplay = ({
  text,
}: {
  text: string;
}): React.ReactElement => {
  const config = getConfig();
  const baseTextColor = getBaseTextColorClassName(config.brand);

  return (
    <div className="flex flex-row items-center space-x-1">
      <Typography size="paragraph" isBold color={black}>
        Status:
      </Typography>
      <Typography size="paragraph" isBold color={baseTextColor}>
        {text}
      </Typography>
    </div>
  );
};

const SubscriptionCardStatus: React.FC<{
  status: SubscriptionStatus;
  id: string;
  logger: Logger;
  nextPaymentDate?: string;
}> = ({ status, nextPaymentDate, id, logger }) => {
  const config = getConfig();
  const baseTextColor = getBaseTextColorClassName(config.brand);

  if (status === 'PAYMENT_FAILED') {
    return (
      <div>
        <div className="flex flex-row items-center mb-2 space-x-1">
          <Typography size="paragraph" isBold color={black}>
            Status:
          </Typography>
          <Typography size="paragraph" color={error500} isBold>
            <div className="flex flex-row items-center space-x-1">
              <ExclamationIcon />
              <span className="inline-block">Payment failed</span>
            </div>
          </Typography>
        </div>
        <Typography size="paragraph" color={baseTextColor}>
          We weren&apos;t able to process your payment, please try again or edit
          your card details.
        </Typography>
      </div>
    );
  }

  if (status === 'CANCELED') {
    return <SubscriptionStatusDisplay text="Cancelled" />;
  }

  if (status === 'PROCESSING') {
    return (
      <div className="space-y-2">
        <SubscriptionStatusDisplay text="Active" />
        <Typography size="paragraph" color={baseTextColor}>
          Your order is currently being processed
        </Typography>
      </div>
    );
  }

  if (status === 'ACTIVE' && nextPaymentDate) {
    return (
      <div className="space-y-1">
        <SubscriptionStatusDisplay text="Active" />
        <div className="flex flex-row items-center space-x-1">
          <Typography size="paragraph" isBold color={black}>
            Next shipment:
          </Typography>
          <Typography size="paragraph" color={baseTextColor}>
            {formatDate(
              mapBrandToAdaptersBrand(config.brand),
              nextPaymentDate,
              {
                dateStyle: 'long',
              },
            )}
          </Typography>
        </div>
      </div>
    );
  }

  // A subscription would need to be in the `CREATED` state for this to occur
  // which is at the time of writing not returned by the API.
  // Message is here for developer friendliness if we ever add new
  // subscription statuses
  logger.error(`Unknown subscription state for subscription with id: ${id}`);

  return null;
};

export const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  id,
  name,
  interval,
  intervalCount,
  photoUrl,
  price,
  status,
  nextPaymentDate,
  children,
  logger,
}) => {
  return (
    <Card>
      <div className="flex flex-col justify-between space-y-5 h-full">
        <div className="space-y-5">
          <div className="flex flex-row justify-between space-x-4">
            <div className="space-y-3">
              <Typography size="md" isBold>
                {name}
              </Typography>
              <div className="flex items-center space-x-1 mt-1">
                <Typography size="sm" isBold>
                  {formatCentsToCurrency(price)}
                </Typography>
                <Typography size="paragraph">
                  /{formatSubscriptionCadence(interval, intervalCount)}
                </Typography>
              </div>
            </div>
            <div className="flex-none">
              <img
                src={photoUrl}
                className="w-20 h-auto rounded-lg overflow-hidden"
              />
            </div>
          </div>
          <SubscriptionCardStatus
            status={status}
            nextPaymentDate={nextPaymentDate}
            id={id}
            logger={logger}
          />
        </div>
        {children}
      </div>
    </Card>
  );
};
