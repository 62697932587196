import React from 'react';
import { useParams } from 'react-router';
import {
  useEducationReadingQuery,
  useMarkEducationReadingCompletedMutation,
  useMarkEducationReadingStartedMutation,
  useNextUnreadEducationReadingLazyQuery,
} from '@customer-frontend/graphql-types';
import { LoadingSpinner, Typography } from '@eucalyptusvc/design-system';
import { useEventService } from '@customer-frontend/events';
import { useAuth } from '@customer-frontend/auth';
import { ContentfulContentType } from '@customer-frontend/contentful';
import { useUrlQuery } from '@customer-frontend/utils';
import { ContentDetail } from './content-detail';
import { FormattedMessage } from 'react-intl';

type ContentDetailTemplateProps = {
  headerClass: string;
  programRoute?: string;
  collectionRoute?: string;
};

export const ContentDetailTemplate: React.FC<ContentDetailTemplateProps> = ({
  headerClass,
  programRoute,
  collectionRoute,
}) => {
  const { readingId } = useParams<{ readingId: string }>();
  const params = useUrlQuery();
  const programId = params.get('programId') ?? undefined;
  const collectionId = params.get('collectionId') ?? undefined;

  const [
    getNextUnreadEducationReading,
    { data: nextReading, loading: nextReadingLoading },
  ] = useNextUnreadEducationReadingLazyQuery();

  const [markEducationReadingStarted] =
    useMarkEducationReadingStartedMutation();
  const [markEducationReadingCompleted] =
    useMarkEducationReadingCompletedMutation({
      onCompleted: () => {
        if (!programId) {
          return;
        }

        getNextUnreadEducationReading({
          variables: { id: programId },
        });
      },
    });

  const { data, loading, error } = useEducationReadingQuery({
    variables: { id: readingId },
    onCompleted: ({ educationReading }) => {
      if (educationReading && !educationReading.startedAt) {
        markEducationReadingStarted({
          variables: { readingId: educationReading.id },
        });
      }
    },
  });
  const { loggedInUser } = useAuth();
  const eventService = useEventService();
  const reading = data?.educationReading;

  React.useEffect(() => {
    if (loggedInUser?.id && reading?.content) {
      const content = reading.content as ContentfulContentType;

      eventService.education.educationArticleViewed({
        userId: loggedInUser.id,
        readingId: reading.id,
        educationArticleId: content.sys.id,
        educationArticleSource: 'contentful',
        educationProgramId: programId,
        type: 'article',
      });
    }
  }, [
    loggedInUser?.id,
    reading?.content,
    reading?.id,
    eventService.education,
    programId,
  ]);

  const handleReadingComplete = React.useCallback(() => {
    if (!reading) {
      return;
    }

    if (loggedInUser?.id && reading?.content) {
      const content = reading.content as ContentfulContentType;

      eventService.education.educationArticleRead({
        userId: loggedInUser.id,
        readingId: reading.id,
        educationArticleId: content.sys.id,
        educationArticleSource: 'contentful',
        educationProgramId: programId,
        type: 'article',
      });
    }
    markEducationReadingCompleted({
      variables: {
        readingId: reading.id,
      },
    });
  }, [
    reading,
    markEducationReadingCompleted,
    eventService.education,
    loggedInUser?.id,
    programId,
  ]);

  if (loading) {
    return (
      <div className="flex justify-center p-10">
        <LoadingSpinner palette="default" />
      </div>
    );
  }

  if (error || !reading) {
    return (
      <div className="flex justify-center p-10">
        <Typography size="md">
          <FormattedMessage
            defaultMessage="Reading could not be found."
            description="Error message when we fail to fetch an article"
          />
        </Typography>
      </div>
    );
  }

  return (
    <ContentDetail
      headerClass={headerClass}
      reading={reading}
      programRoute={programRoute}
      programId={programId}
      collectionRoute={collectionRoute}
      collectionId={collectionId}
      nextReadingId={nextReading?.educationProgram?.nextUnreadReading?.id}
      nextReadingLoading={nextReadingLoading}
      onReadingComplete={handleReadingComplete}
    />
  );
};
