import { FaChevronRight } from 'react-icons/fa';
import clsx from 'clsx';

import { usePurchaseActivationFlow } from './provider';
import { Typography, useResponsive } from '@eucalyptusvc/design-system';

export function Header() {
  const { isMobile } = useResponsive();
  const { headerStages, currentStageIdx } = usePurchaseActivationFlow();

  return (
    <div>
      <nav className="flex flex-row flex-wrap">
        {headerStages.map((hs, idx, arr) => {
          return (
            <div
              key={hs.key}
              className={clsx(
                {
                  'opacity-30': idx > currentStageIdx,
                },
                'flex flex-row items-center',
              )}
            >
              <div className="capitalize px-2 pb-1 md:px-4 md:py-2">
                <Typography
                  size={isMobile ? 'small-text' : 'paragraph'}
                  isBold
                  inheritColor
                >
                  {hs.label}
                </Typography>
              </div>
              {idx + 1 < arr.length && <FaChevronRight className="h-3" />}
            </div>
          );
        })}
      </nav>
    </div>
  );
}
