import { useAuth } from '@customer-frontend/auth';
import { getConfig } from '@customer-frontend/config';
import { useEventService } from '@customer-frontend/events';
import { Logger } from '@customer-frontend/logger';
import { Checkbox } from '@eucalyptusvc/design-system';
import { ChangeEvent, ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';

export const EmailConsentCheckbox = ({
  logger,
  profileRoute,
}: {
  logger: Logger;
  profileRoute: string;
}): ReactElement => {
  const config = getConfig();
  const { formatMessage } = useIntl();
  const { loggedInUser } = useAuth();
  const eventService = useEventService();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  if (!loggedInUser) {
    logger.error(`loggedInUser is not set`);
    return <Redirect to={profileRoute} />;
  }

  const onConsentChange = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setLoading(true);
    const hasConsented = e.target.checked;

    if (hasConsented) {
      await eventService.identify(loggedInUser, 'EMAIL');
    } else {
      await eventService.identify(loggedInUser, 'NONE');
    }

    setChecked(hasConsented);
    setLoading(false);
  };

  return (
    <Checkbox
      isChecked={checked}
      disabled={loading}
      onChange={onConsentChange}
      label={formatMessage(
        {
          defaultMessage:
            'I would like to receive offers and news via email about the products and services of {brandTitle} and our related brands',
          description:
            'Checkbox label for opting in to receive marketing emails',
        },
        { brandTitle: config.title },
      )}
    />
  );
};
