import React, { useMemo, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Card,
  CardPalette,
  Divider,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { ReactComponent as Syringe } from '../../assets/syringe.svg';
import { ReactComponent as Doctor } from '../../assets/doctor.svg';
import { ReactComponent as Tablet } from '../../assets/tablet.svg';
import { ReactComponent as Warning } from '../../assets/warning-triangle.svg';
import {
  ExpandableSectionWithPreview,
  OrderTimeline,
  OrderTimelineProps,
  StageStyle,
} from '@customer-frontend/treatment';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import {
  Maybe,
  OfferingPlanPageOrderTimelineItemFragment,
  OfferingPlanQuery,
  OfferingPlanQueryVariables,
  ProblemType,
} from '@customer-frontend/graphql-types';
import {
  usePurchaseCardContent,
  formatCentsToCurrency,
  OrderDetails,
} from '@customer-frontend/order';
import { getConfig } from '@customer-frontend/config';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import {
  getZendeskRequestUrl,
  useFormatProblemType,
  useTitle,
} from '@customer-frontend/utils';
import { DelayModal } from './delay-modal';
import clsx from 'clsx';

type OrderTimelineType = NonNullable<
  NonNullable<
    NonNullable<OfferingPlanQuery['profile']>['purchases']
  >[number]['orderTimeline']
>;

export type OrderTimelineItemPalette = Record<
  'next' | 'paid' | 'fulfilled',
  string
>;

export type ProblemTypeIcons = Partial<
  Record<
    ProblemType | 'default',
    React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  >
>;

function getSecondaryLabelForOrderTimelineItem(
  otli: OfferingPlanPageOrderTimelineItemFragment,
  nextOrder: Maybe<OfferingPlanPageOrderTimelineItemFragment>,
): React.ReactNode {
  const config = getConfig();
  let content: React.ReactNode;

  if (otli.id === nextOrder?.id) {
    content = (
      <FormattedMessage
        defaultMessage="Next payment: <a>{date}</a>"
        description="Label showing when the next payment will be processed"
        values={{
          date: formatDate(mapBrandToAdaptersBrand(config.brand), otli.date, {
            dateStyle: 'medium',
          }),
          a: (chunks) => (
            <time dateTime={otli.date} className="font-semibold">
              {chunks}
            </time>
          ),
        }}
      />
    );
  }

  if (otli.status === 'PAID') {
    content = (
      <FormattedMessage
        defaultMessage="Order paid: <a>{date}</a>"
        description="Label showing when the order was paid by the user"
        values={{
          date: formatDate(mapBrandToAdaptersBrand(config.brand), otli.date, {
            dateStyle: 'medium',
          }),
          a: (chunks) => (
            <time dateTime={otli.date} className="font-semibold">
              {chunks}
            </time>
          ),
        }}
      />
    );
  }

  return content;
}

function getOrderNumberAdornmentForOrderTimelineItem(
  otli: OfferingPlanPageOrderTimelineItemFragment,
  nextOrder: Maybe<OfferingPlanPageOrderTimelineItemFragment>,
  palette: OrderTimelineItemPalette,
): React.ReactNode | undefined {
  const className = 'px-3 py-1 h-6 text-xs font-semibold rounded';
  if (nextOrder?.id === otli.id) {
    return (
      <div className={clsx(className, palette.next)}>
        <FormattedMessage
          defaultMessage="Next order"
          description="Label in the timeline component showing the next order to be processed"
        />
      </div>
    );
  }
  if (otli.status === 'PAID') {
    return (
      <div className={clsx(className, palette.paid)}>
        <FormattedMessage
          defaultMessage="Processing"
          description="Label in the timeline component showing that an order has been paid"
        />
      </div>
    );
  }
  if (otli.status === 'FULFILLED') {
    return (
      <div className={clsx(className, palette.fulfilled)}>
        <FormattedMessage
          defaultMessage="Fulfilled"
          description="Label in the timeline component showing that the order has been fulfilled"
        />
      </div>
    );
  }
}

function getStageForOrderTimelineItem(
  otli: OfferingPlanPageOrderTimelineItemFragment,
  orders: OfferingPlanPageOrderTimelineItemFragment[],
  nextOrder: Maybe<OfferingPlanPageOrderTimelineItemFragment>,
): StageStyle {
  const i = orders.indexOf(otli);
  if (otli.status === 'FULFILLED') {
    return 'tick';
  }
  if (
    (orders[i + 1] && orders[i + 1]?.id === nextOrder?.id) ||
    (otli.id === nextOrder?.id && i === 0)
  ) {
    return 'circleStrong';
  }
  return 'circle';
}

function getPrimaryLabelAdornmentForOrderTimelineItem(
  otli: OfferingPlanPageOrderTimelineItemFragment,
): React.ReactElement {
  let primaryLabelAdornment = <Syringe className="h-4 w-4" />;
  otli.products?.forEach((p) => {
    p.erxMedicines.forEach((m) => {
      const itemForm = m.itemForm?.toLowerCase();
      if (itemForm?.includes('tablet')) {
        primaryLabelAdornment = <Tablet className="h-4 w-4" />;
      } else if (itemForm?.includes('injectable')) {
        primaryLabelAdornment = <Syringe className="h-4 w-4" />;
      }
    });
  });
  return primaryLabelAdornment;
}

function useOrderTimelineProps({
  orderTimelineItems,
  nextOrder,
  shouldShowAllItems,
  palette,
}: {
  orderTimelineItems?: Maybe<OfferingPlanPageOrderTimelineItemFragment[]>;
  nextOrder?: Maybe<OfferingPlanPageOrderTimelineItemFragment>;
  shouldShowAllItems?: boolean;
  palette: OrderTimelineItemPalette;
}): OrderTimelineProps {
  if (!orderTimelineItems?.length) {
    return { orders: [] };
  }

  let filteredOrders: OfferingPlanPageOrderTimelineItemFragment[] =
    orderTimelineItems;
  let currentOrderIndex = 0;
  if (!shouldShowAllItems) {
    currentOrderIndex = orderTimelineItems.findIndex(
      (o, i) =>
        (orderTimelineItems[i + 1] &&
          orderTimelineItems[i + 1]?.id === nextOrder?.id) ||
        (o.id === nextOrder?.id && i === 0),
    );

    filteredOrders =
      currentOrderIndex !== -1
        ? orderTimelineItems.slice(currentOrderIndex)
        : [];
  }

  return {
    orders: [
      ...filteredOrders.map<OrderTimelineProps['orders'][0]>((otli, i) => ({
        id: otli.id,
        title: (
          <FormattedMessage
            defaultMessage="{ordinal, selectordinal,
            one {#st}
            two {#nd}
            few {#rd}
            other {#th}
        } order"
            description="Specifies nth order in treatment timeline"
            values={{ ordinal: currentOrderIndex + i + 1 }}
          />
        ),
        primaryLabelAdornment:
          getPrimaryLabelAdornmentForOrderTimelineItem(otli),
        primaryLabel: (
          <div>
            {otli.products
              ?.slice(0)
              ?.sort((a, b) => {
                return a.productType === 'RX' && a.productType !== b.productType
                  ? -1
                  : 0;
              })
              ?.map((p) => (
                <div key={p.id}>{p.friendlyName || p.name}</div>
              ))}
          </div>
        ),
        secondaryLabel: (
          <Typography size="paragraph" element="span">
            {getSecondaryLabelForOrderTimelineItem(otli, nextOrder)}
          </Typography>
        ),
        orderNumberAdornment: getOrderNumberAdornmentForOrderTimelineItem(
          otli,
          nextOrder,
          palette,
        ),
        price: formatCentsToCurrency(otli.totalAmount),
        stage: getStageForOrderTimelineItem(
          otli,
          orderTimelineItems,
          nextOrder,
        ),
      })),
      {
        id: 'doctor-check-in',
        title: (
          <FormattedMessage
            defaultMessage="Practitioner checkin"
            description="Title in the timeline component showing a practitioners checkin after all orders are processed"
          />
        ),
        primaryLabel: (
          <div className="flex flex-row gap-x-2">
            <Doctor />
            <div>
              <FormattedMessage
                defaultMessage="Your {isGb, select, true {prescriber} other {practitioner}} will check in with you once you have completed your treatment plan."
                values={{ isGb: getConfig().countryCode === 'GB' }}
              />
            </div>
          </div>
        ),
        stage: nextOrder === null ? 'circleStrong' : 'circle',
      },
    ],
  };
}

export type OfferingPlanPageProps = {
  profileRoute: string;
  switchRoute: (purchaseId: string) => string;
  problemTypeIcons: ProblemTypeIcons;
  palette: {
    orderTimelineItem: OrderTimelineItemPalette;
    refillCard?: CardPalette;
  };
};

export function OfferingPlanPage({
  problemTypeIcons,
  profileRoute,
  switchRoute,
  palette,
}: OfferingPlanPageProps): React.ReactElement {
  const history = useHistory();
  const config = getConfig();
  const { purchaseId } = useParams<{ purchaseId: string }>();
  const urlParams = new URLSearchParams(window.location.search);
  const focusOnDelay = urlParams.get('focus') === 'delay';
  const initialJumpCompleted = useRef(false);

  const [upcomingOrderToDelay, setUpcomingOrderToDelay] = useState<
    { syncGroupId: string; date: string } | undefined
  >();
  const { formatMessage } = useIntl();

  useTitle(
    formatMessage({
      defaultMessage: 'Treatment plan',
      description: 'Page title for the Treatment plan page',
    }),
  );

  const { data, loading, refetch } = useQuery<
    OfferingPlanQuery,
    OfferingPlanQueryVariables
  >(
    gql`
      ${usePurchaseCardContent.fragment}
      fragment OfferingPlanPageOrderTimelineItem on OrderTimelineItem {
        id
        status
        date
        totalAmount
        products {
          id
          name
          friendlyName
          productType
          photo {
            id
            url
          }
          erxMedicines {
            id
            itemForm
          }
        }
        sequenceContext {
          id
          status
        }
      }

      query OfferingPlan {
        profile {
          id
          email
          purchases {
            ...UsePurchaseCardContentPurchase
            id
            status
            problemType
            contexts {
              id
              status
              consultations {
                id
                type
                status
              }
              sequence {
                __typename
                id
                externalLinks {
                  id
                  text
                  url
                }
                products {
                  id
                  name
                  friendlyName
                  productType
                  photo {
                    id
                    url
                  }
                }
              }
            }
            offering {
              id
              status
              friendlyName
              description
              advertisedName
            }
            nextOrder {
              ...OfferingPlanPageOrderTimelineItem
            }
            orderTimeline {
              ...OfferingPlanPageOrderTimelineItem
            }
            canBeSwitched
            syncGroups {
              id
              nextOrderDue
              status
              sequenceContexts {
                id
                sequence {
                  id
                }
              }
            }
          }
        }
      }
    `,
    {
      // This is used to ensure customers won't interact with the page
      // until we have the results of the delay mutation.
      notifyOnNetworkStatusChange: true,
    },
  );

  const [isTreatmentScheduleOpen, setIsTreatmentScheduleOpen] = useState(false);

  const purchase = data?.profile?.purchases?.find((p) => p.id === purchaseId);

  const syncGroupBySequenceContextId = new Map<
    string,
    NonNullable<
      NonNullable<
        NonNullable<OfferingPlanQuery['profile']>['purchases']
      >[number]['syncGroups']
    >[number]
  >();

  for (const syncGroup of purchase?.syncGroups ?? []) {
    for (const sc of syncGroup.sequenceContexts ?? []) {
      syncGroupBySequenceContextId.set(sc.id, syncGroup);
    }
  }

  const orderTimelineProps = useOrderTimelineProps({
    orderTimelineItems: purchase?.orderTimeline,
    nextOrder: purchase?.nextOrder,
    shouldShowAllItems: isTreatmentScheduleOpen,
    palette: palette.orderTimelineItem,
  });

  const purchaseCardContent = usePurchaseCardContent({ purchase });
  const formattedProblemType = useFormatProblemType(purchase?.problemType);
  const ProblemTypeIcon = purchase?.problemType
    ? problemTypeIcons?.[purchase?.problemType] ?? problemTypeIcons?.['default']
    : undefined;

  // we're disabling the delay button if the user has a tiered offering
  // until we have a implemented a better mechanism to handle actions
  // across multiple sequences

  // Each primary sequence context is a separate entity that can be delayed.
  // Hence we want an upcoming order displayed for each of these.
  const upcomingOrders = useMemo(() => {
    const internalUpcomingOrders: OrderTimelineType = [];
    if (purchase?.orderTimeline) {
      const upcomingOrderTimeline = purchase.orderTimeline.filter(
        (ot) => ot.status === 'UPCOMING',
      );
      const sequenceContextIds = new Set<string>(
        upcomingOrderTimeline
          .filter((uot) => uot.sequenceContext?.status === 'ACTIVE')
          .map((uot) => uot.sequenceContext?.id)
          .filter((scid): scid is string => !!scid),
      );
      for (const sequenceContextId of Array.from(sequenceContextIds)) {
        const firstOrderForSequenceContextId = upcomingOrderTimeline.find(
          (ot) => ot.sequenceContext?.id === sequenceContextId,
        );
        if (firstOrderForSequenceContextId) {
          internalUpcomingOrders.push(firstOrderForSequenceContextId);
        }
      }
    }
    return internalUpcomingOrders;
  }, [purchase]);

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (
    !purchase ||
    !purchase.contexts?.length ||
    purchase.contexts.every((c) => c.status !== 'ACTIVE')
  ) {
    return <Redirect to={profileRoute} />;
  }

  const refillNowUrl = getZendeskRequestUrl({
    params: config.weightRefillNowZendeskParams,
    email: data?.profile?.email,
  });
  const rescheduleUrl = getZendeskRequestUrl({
    params: config.weightRescheduleZendeskParams,
    email: data?.profile?.email,
  });
  const resumeNowUrl = getZendeskRequestUrl({
    params: config.weightResumeNowZendeskParams,
    email: data?.profile?.email,
  });

  const hasActiveContext = purchase.contexts.some(
    (c) =>
      c.status === 'ACTIVE' &&
      c.sequence?.products?.some((p) => ['OTC', 'RX'].includes(p.productType)),
  );

  const externalLinks = purchase.contexts.flatMap(
    (c) => c.sequence?.externalLinks ?? [],
  );

  return (
    <article className="space-y-8">
      <div className="space-y-4">
        <Typography isBold size="lg">
          <FormattedMessage defaultMessage="Your treatment plan" />
        </Typography>

        {purchase.problemType && (
          <Card>
            <div className="flex justify-between items-center -my-2">
              <Typography isBold size="md">
                {formattedProblemType}
              </Typography>
              {ProblemTypeIcon && (
                <ProblemTypeIcon role="presentation" className="mb-2 w-10" />
              )}
            </div>

            <Divider variant="separator" mt="xs" />

            {purchase.canBeSwitched && (
              <>
                <div className="flex flex-row justify-between items-center gap-2">
                  <div className="flex flex-col gap-2">
                    {purchase.offering?.advertisedName && (
                      <>
                        <Typography isBold size="medium-paragraph">
                          <FormattedMessage defaultMessage="Your plan" />
                        </Typography>
                        <Typography isBold size="sm">
                          {purchase.offering.advertisedName}
                        </Typography>
                      </>
                    )}
                  </div>
                  <Button
                    onClick={() => history.push(switchRoute(purchaseId))}
                    size="sm"
                    palette="alternate"
                    level="secondary"
                  >
                    <FormattedMessage defaultMessage="Change plan" />
                  </Button>
                </div>

                <Divider variant="separator" />
              </>
            )}

            <div className="space-y-4">
              {purchaseCardContent && <OrderDetails {...purchaseCardContent} />}

              {externalLinks.length > 0 && (
                <div className="flex flex-col gap-2 pb-2">
                  {externalLinks.map((link) => (
                    <a
                      key={link.id}
                      href={link.url}
                      className="text-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography size="medium-paragraph">
                        {link.text}
                      </Typography>
                    </a>
                  ))}
                </div>
              )}

              {hasActiveContext && orderTimelineProps.orders.length > 0 && (
                <ExpandableSectionWithPreview
                  title={
                    <Typography size="medium-paragraph" isBold>
                      <FormattedMessage defaultMessage="Treatment plan schedule" />
                    </Typography>
                  }
                  onToggle={(isExpanded) => {
                    setIsTreatmentScheduleOpen(isExpanded);
                  }}
                >
                  <OrderTimeline {...orderTimelineProps} />
                </ExpandableSectionWithPreview>
              )}

              {!hasActiveContext && (
                <Typography size="medium-paragraph">
                  <FormattedMessage
                    defaultMessage="To resume your treatment, please contact us <a>here</a>."
                    description="Call to action to contact PX if sequence context is paused"
                    values={{
                      a: (chunks) => {
                        return (
                          <a
                            className="underline cursor"
                            href={resumeNowUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {chunks}
                          </a>
                        );
                      },
                    }}
                  />
                </Typography>
              )}
            </div>
          </Card>
        )}
      </div>

      {upcomingOrders.length > 0 && (
        <div
          className="space-y-4"
          ref={(e) => {
            if (focusOnDelay && e && !initialJumpCompleted.current) {
              e.scrollIntoView();
              initialJumpCompleted.current = true;
            }
          }}
        >
          <Typography isBold size="lg">
            <FormattedMessage
              defaultMessage="Your upcoming orders"
              description="Title for upcoming orders on treatment plan page"
            />
          </Typography>

          {upcomingOrders.map((upcomingOrder) => {
            const sequenceContextId = upcomingOrder.sequenceContext?.id;
            const date =
              upcomingOrder.date !== undefined && upcomingOrder.date !== null
                ? String(upcomingOrder.date)
                : undefined;

            const syncGroup = syncGroupBySequenceContextId.get(
              sequenceContextId ?? '',
            );

            let isDelayDisabled = false;
            if (!syncGroup) {
              isDelayDisabled = true;
            } else if (syncGroup.status !== 'ACTIVE') {
              isDelayDisabled = true;
            } else if (syncGroup.nextOrderDue === null) {
              isDelayDisabled = true;
            }

            return (
              <Card key={upcomingOrder.id}>
                {upcomingOrder.products && (
                  <div className="space-y-4">
                    <Typography isBold size="xs">
                      <FormattedMessage
                        defaultMessage="Order contains:"
                        description="Title for an upcoming order on treatment plan page"
                      />
                    </Typography>

                    {upcomingOrder.products.map((product) => (
                      <div
                        key={product.id}
                        className="flex justify-between items-center"
                      >
                        <div className="flex items-center space-x-2">
                          {product.photo?.url && (
                            <img
                              src={product.photo.url}
                              className="w-14 h-14 rounded"
                            />
                          )}
                          <Typography size="paragraph">
                            {product.friendlyName}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <Divider variant="separator" />

                <div className="space-y-4">
                  <div className="flex justify-between">
                    <Typography isBold size="medium-paragraph">
                      <FormattedMessage
                        defaultMessage="Next order total"
                        description="Title for amount due in upcoming order"
                      />
                    </Typography>
                    <Typography isBold size="medium-paragraph">
                      {formatCentsToCurrency(upcomingOrder.totalAmount)}
                    </Typography>
                  </div>

                  <Typography size="medium-paragraph">
                    <FormattedMessage
                      defaultMessage="This will automatically be deducted from your default payment method on {date}."
                      values={{
                        date: formatDate(
                          mapBrandToAdaptersBrand(config.brand),
                          upcomingOrder.date,
                          {
                            dateStyle: 'short',
                          },
                        ),
                      }}
                      description="Explanation of when amount will be charged"
                    />
                  </Typography>

                  {isDelayDisabled && (
                    <Card palette={palette.refillCard}>
                      <div className="space-y-2">
                        <div className="flex space-x-2 items-center">
                          <Warning className="h-4 w-4" />
                          <Typography isBold size="paragraph">
                            <FormattedMessage
                              defaultMessage="Need to reschedule your order?"
                              description="Title for requesting a reschedule call to action"
                            />
                          </Typography>
                        </div>

                        <Typography size="paragraph">
                          <FormattedMessage
                            defaultMessage="If you require your order to be rescheduled, please request the new order date <a>here</a>."
                            description="Body for requesting a reschedule call to action"
                            values={{
                              a: (chunks) => {
                                return (
                                  <a
                                    className="underline cursor"
                                    href={rescheduleUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {chunks}
                                  </a>
                                );
                              },
                            }}
                          />
                        </Typography>
                      </div>
                    </Card>
                  )}

                  <Card palette={palette.refillCard}>
                    <div className="space-y-2">
                      <div className="flex space-x-2 items-center">
                        <Warning className="h-4 w-4" />
                        <Typography isBold size="paragraph">
                          <FormattedMessage
                            defaultMessage="Need a refill now?"
                            description="Title for requesting a refill call to action"
                          />
                        </Typography>
                      </div>

                      <Typography size="paragraph">
                        <FormattedMessage
                          defaultMessage="If you require a refill now, please request an early refill <a>here</a>."
                          description="Body for requesting a refill call to action"
                          values={{
                            a: (chunks) => {
                              return (
                                <a
                                  className="underline cursor"
                                  href={refillNowUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {chunks}
                                </a>
                              );
                            },
                          }}
                        />
                      </Typography>
                    </div>
                  </Card>

                  {!isDelayDisabled && date && syncGroup && (
                    <Button
                      isFullWidth
                      level="secondary"
                      onClick={() => {
                        setUpcomingOrderToDelay({
                          syncGroupId: syncGroup.id,
                          date: syncGroup.nextOrderDue,
                        });
                      }}
                    >
                      <Typography size="large-paragraph" isBold>
                        <FormattedMessage
                          defaultMessage="Delay"
                          description="Button text for delaying an upcoming order"
                        />
                      </Typography>
                    </Button>
                  )}
                </div>
              </Card>
            );
          })}
        </div>
      )}

      {!!upcomingOrderToDelay && (
        <DelayModal
          onClose={() => setUpcomingOrderToDelay(undefined)}
          upcomingOrder={upcomingOrderToDelay}
          onDelaySuccess={refetch}
        />
      )}
    </article>
  );
}
