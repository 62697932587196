import { PatientAndAddressAttributes } from './types';
import { useProfileAndResidentialAddress } from '@customer-frontend/services';
import { useMemo } from 'react';

export const usePatientProfileAndAddressAttrs =
  (): PatientAndAddressAttributes => {
    const resp = useProfileAndResidentialAddress();
    const { firstName, lastName, birthday, residentialAddress } =
      resp?.data?.profile ?? {};
    const { line1, line2, city, postalCode, state, country } =
      residentialAddress ?? {};

    return {
      profileData: useMemo(() => {
        return {
          firstName: firstName ?? '',
          lastName: lastName ?? '',
          birthday: birthday ?? '',
          residentialAddress: {
            line1: line1 ?? '',
            line2: line2 ?? '',
            city: city ?? '',
            postalCode: postalCode ?? '',
            state: state ?? '',
            country: country ?? '',
          },
        };
      }, [
        firstName,
        lastName,
        birthday,
        line1,
        line2,
        city,
        postalCode,
        state,
        country,
      ]),
      loading: resp.loading,
    };
  };
