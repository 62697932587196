import { useCallback, useEffect, useState, useRef } from 'react';
import {
  Button,
  Card,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import {
  calculateDiscountedPrice,
  DeliveryInstructions,
  fieldsToDeliveryInstructions,
  formatCentsToCurrency,
  getDiscountFromLocalStorage,
  getStripePaymentMethod,
  getZipURLParams,
  PaymentForm,
  PaymentPayload,
  removeDiscountFromLocalStorage,
  ShippingValidationStatus,
  useConsultationPaymentHandler,
  usePersistedDiscountFromURL,
  useShippingForm,
  useZipRedirect,
} from '@customer-frontend/order';
import {
  DiscountCodeFormFragment,
  Maybe,
  PaymentGateway,
  UpfrontConsultPayPageQuery,
  useConsultationPricingQuery,
} from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { getFullAddressString, scrollToRef } from '@customer-frontend/utils';
import { ReactComponent as CheckCircle } from '../../assets/icons/check-circle.svg';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { AddressFields, IntlAddressInput } from '@customer-frontend/intl';
import { useEnablePaymentIntent } from '@customer-frontend/services';

export const UpfrontPaymentConsultPaymentForm = ({
  user,
  consultation,
  onPaymentSuccess,
  validateShippingAddress,
  paymentIntentReturnUrl,
}: {
  user: NonNullable<UpfrontConsultPayPageQuery['profile']>;
  consultation: NonNullable<UpfrontConsultPayPageQuery['consultation']>;
  validateShippingAddress: boolean;
  onPaymentSuccess: () => void;
  paymentIntentReturnUrl?: string;
}): React.ReactElement => {
  const config = getConfig();
  const notify = useNotification();
  const { formatMessage } = useIntl();
  const usePaymentIntents = useEnablePaymentIntent();

  const { clearCode } = usePersistedDiscountFromURL();

  const [fullResidentialAddress, setFullResidentialAddress] = useState('');

  const paymentFormRef = useRef<HTMLInputElement>(null);
  const defaultPaymentGateway = user.defaultPaymentGateway || 'STRIPE';
  const [gateway, setGateway] = useState<Maybe<PaymentGateway>>(
    getZipURLParams().result ? 'ZIP' : defaultPaymentGateway,
  );
  const [showSimplifiedAddress, setShowSimplifiedAddress] = useState(true);
  const [editingCardDetails, setEditingCardDetails] = useState(() => {
    return !getStripePaymentMethod(user.paymentMethods)?.cardMeta;
  });

  const { data: pricingData } = useConsultationPricingQuery({
    variables: {
      id: consultation.id,
    },
  });
  const consultPrice = pricingData?.consultation?.price?.fullPrice || 0;

  const { persistedDiscountCode } = usePersistedDiscountFromURL();
  const [discount, setDiscount] = useState<DiscountCodeFormFragment>();

  const { totalPrice } = calculateDiscountedPrice({
    subtotal: consultPrice || 0,
    discount,
    products: [],
  });

  const { handlePayment, loading } = useConsultationPaymentHandler({
    user,
    gateway,
    discount,
    consultation,
    detailsChanged: editingCardDetails,
    onPaymentSuccess: () => {
      clearCode();
      onPaymentSuccess();
    },
    usePaymentIntents,
    paymentIntentReturnUrl,
  });

  useZipRedirect({
    enabled: !loading,
    onRedirectFromZip: () => {
      scrollToRef(paymentFormRef, 100);
    },
    onApproved: (zipCheckoutId) => {
      const discount = getDiscountFromLocalStorage('CONSULTATION_PAID');
      removeDiscountFromLocalStorage('CONSULTATION_PAID');

      const paymentPayload = {
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        value: totalPrice,
        couponCode: discount,
        zipCheckoutId,
        address: {
          line1: user.address?.line1 ?? '',
          line2: user.address?.line2 ?? '',
          city: user.address?.city ?? '',
          postalCode: user.address?.postalCode ?? '',
          state: user.address?.state ?? '',
          country: config.country,
        },
      };
      handlePayment(paymentPayload);
    },
  });

  const {
    errors,
    register,
    setValue,
    handleSubmit,
    onShippingAddressChanged,
    isValidatingShippingAddress,
    isShippingAddressWithinRange,
    validate: validateShippingForm,
    validateAddress,
    isShippingAddressCompletedForPostcodeValidation,
    watch,
  } = useShippingForm({
    enableAddressValidation: validateShippingAddress,
    phone: user.phone,
    lastName: user.legalLastName,
    firstName: user.legalFirstName,
    shippingAddress: user.address,
  });

  const enableDeliveryInstructions = useFeatureFlagBoolean(
    'DELIVERY_INSTRUCTIONS_INPUT',
  );

  const onAddressChange = useCallback(
    (data: AddressFields) => {
      onShippingAddressChanged(data);
      setFullResidentialAddress(getFullAddressString(data));
    },
    [onShippingAddressChanged, setFullResidentialAddress],
  );

  useEffect(() => {
    if (user.address?.line1) {
      setValue('shippingAddress', {
        ...user.address,
      });
      setFullResidentialAddress(getFullAddressString(user.address));
    }
  }, [setValue, user]);

  const submit = (payload?: Partial<PaymentPayload>): (() => Promise<void>) =>
    handleSubmit(async ({ firstName, lastName, phone, shippingAddress }) => {
      const { deliveryOption, deliveryOther, ...commonShippingAddress } =
        shippingAddress;

      const deliveryInstructions = fieldsToDeliveryInstructions({
        deliveryOption,
        deliveryOther,
      });

      const paymentPayload = {
        ...payload,
        firstName,
        lastName,
        phone,
        value: totalPrice,
        couponCode: discount?.code,
        address: {
          ...commonShippingAddress,
          country: config.country,
          deliveryInstructions: enableDeliveryInstructions
            ? deliveryInstructions
            : undefined,
        },
      };

      if (validateShippingAddress) {
        const isWithinRange = await validateAddress();
        if (!isWithinRange) {
          notify.error({ message: 'Shipping address not within range' });
          return;
        }
      }

      try {
        handlePayment(paymentPayload);
      } catch (error) {
        const descriptions = getErrorMessageDescriptorsFromError(error);
        descriptions.forEach((descriptor) =>
          notify.error({ message: formatMessage(descriptor) }),
        );
      }
    });

  const handleAlternativePaymentMethod = async (
    payload: Partial<PaymentPayload>,
  ): Promise<void> => {
    const valid = await validateShippingForm();
    if (!valid) {
      payload.stripeEvent?.complete('fail');
      return;
    }

    return submit(payload)();
  };

  return (
    <form onSubmit={submit()} className="space-y-8">
      <Card>
        <Typography isBold size="md">
          <FormattedMessage defaultMessage="Provide your delivery address" />
        </Typography>
        <div className="flex flex-col space-y-3">
          <div>
            <IntlAddressInput
              className="mt-3"
              name="shippingAddress"
              errors={errors.shippingAddress ?? {}}
              registerField={register}
              useAutoComplete
              onChange={onAddressChange}
              showSimplifiedForm={showSimplifiedAddress}
            />
            {fullResidentialAddress && (
              <Typography size="paragraph">{fullResidentialAddress}</Typography>
            )}
          </div>
          {validateShippingAddress && fullResidentialAddress !== '' && (
            <ShippingValidationStatus
              isValidatingShippingAddress={isValidatingShippingAddress}
              isShippingAddressWithinRange={isShippingAddressWithinRange}
              isShippingAddressCompleted={
                isShippingAddressCompletedForPostcodeValidation
              }
            />
          )}
          {showSimplifiedAddress && (
            <Button
              isFullWidth
              palette={'default'}
              level="secondary"
              onClick={(): void => setShowSimplifiedAddress(false)}
            >
              <FormattedMessage
                description="Label for entering address manually"
                defaultMessage="Enter manually"
              />
            </Button>
          )}
          {enableDeliveryInstructions && (
            <DeliveryInstructions
              registerField={register}
              watch={watch}
              name="shippingAddress"
              onChange={onShippingAddressChanged}
              errors={errors.shippingAddress ?? {}}
            />
          )}
        </div>
      </Card>
      <Card>
        <div className="space-y-6">
          <div ref={paymentFormRef}>
            <PaymentForm
              gateway={gateway}
              isLoading={loading}
              discountCode={discount}
              subtotal={consultPrice}
              totalPrice={totalPrice}
              stage="CONSULTATION_PAID"
              zip={user.zip}
              onGatewayChange={setGateway}
              paymentMethods={user.paymentMethods}
              onEdit={() => setEditingCardDetails(true)}
              isEditingStripeDetails={editingCardDetails}
              defaultDiscountCode={persistedDiscountCode}
              skipDiscountCodeProductValidation={true}
              onDiscountCodeChange={(code): void => setDiscount(code)}
              onAlternativePaymentMethod={(event): Promise<void> =>
                handleAlternativePaymentMethod({ stripeEvent: event })
              }
              headerComponent={
                <div className="mb-3">
                  <Typography size="md" isBold>
                    <FormattedMessage defaultMessage="Choose a payment method" />
                  </Typography>
                </div>
              }
              products={[]}
            />
          </div>
          <Button
            isFullWidth
            isSubmit
            palette={getPrimaryButtonPalette(config.brand)}
            isLoading={loading}
            isDisabled={loading || !isShippingAddressWithinRange}
          >
            <FormattedMessage
              defaultMessage="Confirm {totalPrice} payment"
              values={{
                totalPrice: formatCentsToCurrency(totalPrice),
              }}
            />
          </Button>
          <div className="flex flex-col gap-2">
            {[
              defineMessage({
                defaultMessage:
                  'If you’re found unsuitable during your consult or decide not to proceed with our program after your practitioner call, we will refund you the amount in full',
              }),
              defineMessage({ defaultMessage: '30-day money back guarantee' }),
              defineMessage({ defaultMessage: 'Free cancellation anytime' }),
            ].map((copy) => (
              <div className="flex gap-2" key={copy.defaultMessage}>
                <div className="w-4 h-4 pt-0.5">
                  <CheckCircle />
                </div>
                <Typography color="black" size="paragraph">
                  {formatMessage(copy)}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </form>
  );
};
