import {
  FeatureFlagClient,
  FlagConfigOptions,
  useFeatureFlagClient,
} from '@customer-frontend/feature-flags';
import {
  Maybe,
  TreatmentStatus,
  useSpecialisedNutritionSupportQuery,
} from '@customer-frontend/graphql-types';
import { isSaxenda } from '@customer-frontend/utils';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SpecialisedNutritionSupportExperimentVariant,
  SPECIALISED_NUTRITION_SUPPORT_EXPERIMENT_KEY,
} from 'utils/feature-flags';
import { routes } from 'utils/routes';
import {
  NutritionSupportExperimentSchedules,
  ProductVariantInventorySkuWithName,
} from './types';
import { useInSegment } from '@customer-frontend/services';

export const useCanSeeOrderScreen = (
  treatmentStatus: Maybe<TreatmentStatus>,
  isLoading?: boolean,
): void => {
  const history = useHistory();

  useEffect(() => {
    const planStatuses: TreatmentStatus[] = ['CREATED', 'FOLLOW_UP', 'REVIEW'];
    if (isLoading) {
      return;
    }
    if (treatmentStatus) {
      if (!planStatuses.includes(treatmentStatus)) {
        history.replace(routes.profile);
      }
    }
  }, [treatmentStatus, history, isLoading]);
};

export const NUTRITION_SUPPORT_PRODUCT_SKU = 'SNSUPPORT';

const getNutritionSupportFlag = (
  featureFlagClient: FeatureFlagClient,
  flagOptions: FlagConfigOptions = {},
): SpecialisedNutritionSupportExperimentVariant => {
  return featureFlagClient.getCustom<SpecialisedNutritionSupportExperimentVariant>(
    SPECIALISED_NUTRITION_SUPPORT_EXPERIMENT_KEY,
    { defaultValue: 'not-enrolled', ...flagOptions },
  );
};

const useNutritionSupportFlag = (
  flagOptions?: FlagConfigOptions,
): SpecialisedNutritionSupportExperimentVariant => {
  const featureFlagClient = useFeatureFlagClient();

  return getNutritionSupportFlag(featureFlagClient, flagOptions);
};

export const fireNutritionSupportExposure = (
  featureFlagClient: FeatureFlagClient,
): void => {
  getNutritionSupportFlag(featureFlagClient);
};

export const useNutritionSupportInitialPatientsExperiment = (): boolean => {
  const flagValue = useNutritionSupportFlag({
    disableExposureTracking: true,
  });
  return flagValue === 'variation';
};

export const useNutritionSupportExperimentQuery = (
  treatmentId = '',
): NutritionSupportExperimentSchedules => {
  const isNutritionSupportEnabled =
    useNutritionSupportInitialPatientsExperiment();

  const { data, loading, refetch } = useSpecialisedNutritionSupportQuery({
    variables: {
      treatmentId: treatmentId,
      productSKU: NUTRITION_SUPPORT_PRODUCT_SKU,
    },
    skip: !treatmentId || !isNutritionSupportEnabled,
  });

  const schedules = data?.treatment?.specialisedNutritionSupportSchedule;
  const hasNutritionSupport = !!schedules?.length;

  const product = data?.treatment?.product;

  return {
    isNutritionSupportEnabled,
    productSKU: NUTRITION_SUPPORT_PRODUCT_SKU,
    loading,
    hasNutritionSupport,
    schedule: schedules?.[0],
    product,
    refetch,
  };
};

export const getExperimentProductName = (
  product: ProductVariantInventorySkuWithName,
  isNutritionSupportEnabled: boolean,
  hasNutritionSupport: boolean,
): string => {
  if (isSaxenda(product) && isNutritionSupportEnabled && hasNutritionSupport) {
    return 'Weight Reset Program (Saxenda) + 1:1 Dietitian Support';
  }
  return product.friendlyName ?? product.name;
};

export const useIsMobileAppInstalled = (): boolean | undefined => {
  const { data: IsMobileAppInstalled } = useInSegment({
    variables: {
      segmentName: 'COMPLETED_MOBILE_ONBOARDING_SCREENS',
    },
  });

  return IsMobileAppInstalled?.inSegment;
};
