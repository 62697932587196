import { DiabetesRiskLevel, Maybe } from '@customer-frontend/graphql-types';

export const formatDiabetesRiskLevel = (
  diabetesRiskLevel: Maybe<DiabetesRiskLevel>,
): string => {
  if (!diabetesRiskLevel) {
    return 'increased';
  }

  return diabetesRiskLevel.toString().toLowerCase();
};

export const formatDiabetesRiskProbability = (
  diabetesRiskProbability: Maybe<number>,
): string | undefined => {
  if (!diabetesRiskProbability) {
    return undefined;
  }

  return Math.round(1 / diabetesRiskProbability).toString();
};

export const getRiskLevelExplanationCopy = (
  diabetesRiskLevel: Maybe<DiabetesRiskLevel>,
  diabetesRiskProbability: Maybe<number>,
): string => {
  const formattedDiabetesRiskProbability = formatDiabetesRiskProbability(
    diabetesRiskProbability,
  );

  if (!formattedDiabetesRiskProbability) {
    return '';
  }

  switch (diabetesRiskLevel) {
    case 'HIGH':
      return `**This means that you have approximately a one in ${formattedDiabetesRiskProbability} chance of developing diabetes in the next 5 years. This may impact how we can best take care of your health.**`;
    case 'INTERMEDIATE':
      return `**Based on your risk level approximately one in every ${formattedDiabetesRiskProbability} persons will develop diabetes in the next 5 years.**`;
    default:
      return '';
  }
};

export const getTestRequestReasonCopy = (
  diabetesRiskLevel: Maybe<DiabetesRiskLevel>,
): string => {
  switch (diabetesRiskLevel) {
    case 'HIGH':
      return 'determine whether this program is safe for you.';
    case 'INTERMEDIATE':
    default:
      return 'help me determine how I can best take care of your health.';
  }
};

export const getTestRequestOutcomeCopy = (
  diabetesRiskLevel: Maybe<DiabetesRiskLevel>,
): string => {
  switch (diabetesRiskLevel) {
    case 'HIGH':
      return 'move forward with the consultation.';
    case 'INTERMEDIATE':
    default:
      return 'see if we need to adjust your program.';
  }
};
